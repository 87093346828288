import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, IReactionDisposer, makeAutoObservable, makeObservable, observable, reaction } from 'mobx';
import { MessageEvent } from 'sockjs-client';
import { v4 as uuid } from 'uuid';
import { UserRequest } from '../../../Api/WebSocket/UserRequest';
import { AuthenticationService } from '../../../Service/Authentication/AuthenticationService';
import { getApiUrl } from '../../../Util/Api/Resources/getApiUrl';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';
import { promptCheckIfApiIsReachable, setApiReachable } from '../../../Util/Hooks/useApiReachability';
import { transformISO8601ToDate } from '../../../Util/Serialization/Transformers/transformISO8601ToDate';
import { ReconnectingWebSocket } from './ReconnectingWebSocket';

export interface IncomingWebSocketMessageHandler
{
    type: string;
    handler: (notification: any) => void;
}

export class WebSocketService extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // TODO: Fix injection error in Android App (DependencyInjection.js => Reflect.getMetadata returning null)
    // @inject authenticationService: AuthenticationService;

    // ------------------------- Properties -------------------------

    private readonly authenticationService: AuthenticationService;
    private readonly clientInstanceUuid: string;
    handlersByType = observable.map<string, IncomingWebSocketMessageHandler[]>();
    isRegistered: boolean;
    private readonly querySubscriptions: Map<string, QuerySubscription>;
    private readonly hardQuerySubscriptionsByQuery: Map<string, HardQuerySubscription>;
    private readonly webSocket: ReconnectingWebSocket;
    private readonly awaitingCallbacksByNotificationType = observable.map<string, {callback: ((response: Record<string, any>) => void), predicate: ((response: Record<string, any>) => boolean)}[]>();
    private readonly listenerByNotificationType = new Map<string, any[]>();

    // ------------------------ Constructor -------------------------

    constructor(
        authenticationService: AuthenticationService,
        clientInstanceUuid: string
    )
    {
        super();
        makeObservable<WebSocketService, 'isConnected' | 'sendMessage' | 'receiveMessage' | 'setIsRegistered'>(
            this,
            {
                handlersByType: observable,
                isRegistered: observable,
                isConnected: computed,
                startQuery: action.bound,
                endHardQuerySubscription: action.bound,
                endQuerySubscription: action.bound,
                close: action.bound,
                sendMessage: action.bound,
                sendUserRequest: action.bound,
                receiveMessage: action.bound,
                setIsRegistered: action.bound,
            },
        );
        this.clientInstanceUuid = clientInstanceUuid;
        this.querySubscriptions = new Map<string, QuerySubscription>();
        this.hardQuerySubscriptionsByQuery = new Map<string, HardQuerySubscription>();
        this.authenticationService = authenticationService;
        this.webSocket = this.newWebSocket();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    private get isConnected(): boolean
    {
        return this.webSocket.currentStatus === 'CONNECTED';
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    public register(): () => void
    {
        return reaction(
            () => this.isConnected,
            isConnected => {
                if (isConnected)
                {
                    this.sendMessage({
                        command: 'authenticate_start',
                        authentication: {
                            key: this.authenticationService.account!.key,
                            password: this.authenticationService.account!.token,
                        },
                        clientInstanceUuid: this.clientInstanceUuid,
                    });
                }
                else
                {
                    this.setIsRegistered(false);
                }
            },
            {
                fireImmediately: true,
            },
        )
    }

    public startQuery(query: WebSocketQuery, ...handlers: IncomingWebSocketMessageHandler[]): QuerySubscription
    {
        const hardQuerySubscription = (() => {
            if (!this.hardQuerySubscriptionsByQuery.has(query.asString))
            {
                const hardQuerySubscriptionUuid = uuid();
                const reactionDisposer = reaction(
                    () => this.isRegistered,
                    isRegistered => {
                        if (isRegistered)
                        {
                            this.sendMessage({
                                command: 'query_start',
                                query: query,
                                queryUuid: hardQuerySubscriptionUuid,
                                resultsSince: hardQuerySubscription.upToDateUntilAtLeast,
                            })
                        }
                    },
                    {
                        fireImmediately: true,
                    },
                );
                const hardQuerySubscription = new HardQuerySubscription(
                    query,
                    hardQuerySubscriptionUuid,
                    new Map<string, QuerySubscription>(),
                    this,
                    reactionDisposer
                );
                this.hardQuerySubscriptionsByQuery.set(query.asString, hardQuerySubscription);
            }
            return this.hardQuerySubscriptionsByQuery.get(query.asString)!;
        })();
        const handlersByType = (() => {
            const handlersByType = new Map<string, IncomingWebSocketMessageHandler[]>();
            handlers.forEach(handler => registerHandler(handler, handlersByType));
            return handlersByType;
        })();
        const queryUuid = uuid();
        const querySubscription = new QuerySubscription(hardQuerySubscription, queryUuid, handlersByType, this);
        this.querySubscriptions.set(queryUuid, querySubscription);
        hardQuerySubscription.subscriptionsById.set(queryUuid, querySubscription);
        return querySubscription;
    }

    endHardQuerySubscription(hardQuerySubscriptionUuid: string): void
    {
        const hardQuerySubscription = this.hardQuerySubscription(hardQuerySubscriptionUuid);
        if (hardQuerySubscription === undefined)
        {
            throw new IllegalStateException();
        }
        this.hardQuerySubscriptionsByQuery.delete(hardQuerySubscription.query.asString);
        this.sendMessage({
            command: 'query_end',
            queryUuid: hardQuerySubscriptionUuid,
        });
    }

    endQuerySubscription(querySubscriptionUuid: string): void
    {
        const hardQuerySubscription = this.querySubscriptions.get(querySubscriptionUuid)?.hardQuerySubscription;
        if (hardQuerySubscription === undefined)
        {
            throw new IllegalStateException();
        }
        hardQuerySubscription.subscriptionsById.delete(querySubscriptionUuid);
        this.querySubscriptions.delete(querySubscriptionUuid);

        if (hardQuerySubscription.subscriptionsById.size === 0)
        {
            hardQuerySubscription.dispose();
        }
    }

    private newWebSocket(): ReconnectingWebSocket
    {
        return new ReconnectingWebSocket(
            getApiUrl('legacy_events?api_version=V2'),
            reconnectingWebSocket => {
                setApiReachable();
            },
            (reconnectingWebSocket, messageEvent) => this.receiveMessage(messageEvent),
            () => {
                promptCheckIfApiIsReachable();
            },
            {
                logMessages: true,
            }
        )
    }

    public close()
    {
        this.webSocket.close();
    }

    private sendMessage(message: any)
    {
        this.webSocket.send(message);
    }

    public sendUserRequest(request: UserRequest)
    {
        this.webSocket.send({command: 'user_request', request});
    }

    public sendCartPulse(cartId: number)
    {
        this.webSocket.send({
            command: 'cart_pulse',
            cartId,
        });
    }

    private hardQuerySubscription(hardQuerySubscriptionUuid: string): HardQuerySubscription | undefined
    {
        return Array.from(this.hardQuerySubscriptionsByQuery.values())
            .find(hardQuerySubscriptionQuery => hardQuerySubscriptionQuery.uuid === hardQuerySubscriptionUuid);
    }

    private receiveMessage(event: MessageEvent)
    {
        const notification = JSON.parse(event.data);
        const type: string = notification.notification_type ?? notification.type;
        const notificationTimestamp: Date | undefined = transformISO8601ToDate(notification.eventTimestamp);

        if (type === 'authenticate_start_ack')
        {
            this.setIsRegistered(true);
            return;
        }
        else if (type === 'authenticate_fail')
        {
            this.setIsRegistered(false);
            console.error('Websocket registration failed');
            return;
        }
        else if (type === 'query_start_ack')
        {
            const hardQuerySubscriptionUuid = notification['queryUuid'];
            const hardQuerySubscription = this.hardQuerySubscription(hardQuerySubscriptionUuid);

            if (hardQuerySubscription === undefined)
            {
               console.warn(`Received query start acknowledgement for non-existent query ${hardQuerySubscriptionUuid}`);
            }
            else
            {
                hardQuerySubscription.setIsOpen(true);
            }
        }
        else
        {
            this.checkAwaitingCallbacks(notification);
            flatten(
                Array.from(this.hardQuerySubscriptionsByQuery.values())
                    .map(hardQuerySubscription => {
                        if (notificationTimestamp !== undefined)
                        {
                            hardQuerySubscription.setUpToDateUntilAtLeast(notificationTimestamp);
                        }
                        return Array.from(hardQuerySubscription.subscriptionsById.values())
                    })
            )
                .forEach(querySubscription => {
                    if (querySubscription.handlersByType.has(type))
                    {
                        querySubscription.handlersByType.get(type)!
                            .forEach(handler => handler.handler(notification));
                    }
                });
        }

        if (this.handlersByType.has(type))
        {
            this.handlersByType.get(type)
                .forEach(
                    handler =>
                        handler.handler(notification)
                );
        }
    }

    private checkAwaitingCallbacks(notification: any)
    {
        const type = notification.notification_type ?? notification.type;

        if (this.awaitingCallbacksByNotificationType.has(type))
        {
            const allCallbacks = this.awaitingCallbacksByNotificationType.get(type) ?? [];
            const firingCallbacks = allCallbacks.filter(value => value.predicate(notification));

            firingCallbacks.forEach(value => value.callback(notification));

            if (allCallbacks.length === firingCallbacks.length)
                this.awaitingCallbacksByNotificationType.delete(type);
            else
                this.awaitingCallbacksByNotificationType.set(
                    type,
                    allCallbacks.filter(value => !firingCallbacks.includes(value)),
                );
        }
    }

    // TODO: Improve using the new client instance UUID, and type message as UserRequest?
    public async exchange(
        message: Record<string, any>,
        responseNotificationType: string,
        predicate: (response: Record<string, any>) => boolean): Promise<Record<string, any>>
    {
        this.sendMessage(message);

        return new Promise<Object>(
            resolve =>
            {
                if (this.awaitingCallbacksByNotificationType.has(responseNotificationType))
                    this.awaitingCallbacksByNotificationType.set(
                        responseNotificationType,
                        this.awaitingCallbacksByNotificationType.get(responseNotificationType)!.concat({callback: resolve, predicate: predicate}),
                    );
                else
                    this.awaitingCallbacksByNotificationType.set(responseNotificationType, [{callback: resolve, predicate: predicate}]);
            },
        );
    }

    public onMessage(
        notificationType: string,
        onMessage: (message: any) => void
    )
    {
        const handler = {
            type: notificationType,
            handler: onMessage,
        };

        registerHandler(handler, this.handlersByType);

        return () =>
            unregisterHandler(handler, this.handlersByType);
    }


    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private setIsRegistered(isRegistered: boolean): void
    {
        this.isRegistered = isRegistered;
    }
}

export class ManagementQuery
{
    readonly type = 'Management';
    readonly businessId: number;
    constructor(businessId: number)
    {
        this.businessId = businessId;
    }
    get asString(): string
    {
        return JSON.stringify({
            type: this.type,
            businessId: this.businessId,
        })
    }
}
export class BusinessQuery
{
    readonly type = 'Business';
    readonly businessId: number;
    constructor(businessId: number)
    {
        this.businessId = businessId;
    }
    get asString(): string
    {
        return JSON.stringify({
            type: this.type,
            businessId: this.businessId,
        })
    }
}

export type WebSocketQuery = ManagementQuery | BusinessQuery;

export class HardQuerySubscription
{
    private readonly webSocketService: WebSocketService;
    readonly subscriptionsById: Map<string, QuerySubscription>;
    private readonly connectReactionDisposer: IReactionDisposer;

    public readonly uuid: string;
    public readonly query: WebSocketQuery;
    public isOpen: boolean;
    private _upToDateUntilAtLeast: Date;

    constructor(
        query: WebSocketQuery,
        uuid: string,
        subscriptionsById: Map<string, QuerySubscription>,
        webSocketService: WebSocketService,
        connectReactionDisposer: IReactionDisposer,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.query = query;
        this.uuid = uuid;
        this.subscriptionsById = subscriptionsById;
        this.webSocketService = webSocketService;
        this.connectReactionDisposer = connectReactionDisposer;
        this.isOpen = false;
        this._upToDateUntilAtLeast = new Date();
    }

    public setIsOpen(isOpen: boolean): void
    {
        this.isOpen = isOpen;
    }

    public dispose(): void
    {
        this.connectReactionDisposer();
        this.webSocketService.endHardQuerySubscription(this.uuid);
    }

    get upToDateUntilAtLeast(): Date
    {
        return this._upToDateUntilAtLeast;
    }

    public setUpToDateUntilAtLeast(date: Date): void
    {
        this._upToDateUntilAtLeast = date;
    }
}

export class QuerySubscription
{
    private readonly webSocketService: WebSocketService;
    readonly handlersByType: Map<string, IncomingWebSocketMessageHandler[]>;

    public readonly uuid: string;
    public readonly hardQuerySubscription: HardQuerySubscription;

    constructor(
        hardQuerySubscription: HardQuerySubscription,
        uuid: string,
        handlersByType: Map<string, IncomingWebSocketMessageHandler[]>,
        webSocketService: WebSocketService,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.hardQuerySubscription = hardQuerySubscription;
        this.uuid = uuid;
        this.handlersByType = handlersByType;
        this.webSocketService = webSocketService;
    }

    public get isOpen()
    {
        return this.hardQuerySubscription.isOpen;
    }

    public dispose(): void
    {
        this.webSocketService.endQuerySubscription(this.uuid);
    }

    get upToDateUntilAtLeast(): Date
    {
        return this.hardQuerySubscription.upToDateUntilAtLeast;
    }
}

function registerHandler(
    handler: IncomingWebSocketMessageHandler,
    handlersByType: Map<string, IncomingWebSocketMessageHandler[]>
): void
{
    if (!handlersByType.has(handler.type))
    {
        handlersByType.set(handler.type, []);
    }

    handlersByType.get(handler.type)!.push(handler);
}

function unregisterHandler(
    handler: IncomingWebSocketMessageHandler,
    handlersByType: Map<string, IncomingWebSocketMessageHandler[]>
)
{
    if (handlersByType.has(handler.type))
    {
        const handlers = handlersByType.get(handler.type)!;
        handlers.splice(
            handlers.indexOf(handler),
            1
        );
    }
}

function flatten<T>(arrayOfArrays: T[][]): T[]
{
    const result: T[] = [];
    arrayOfArrays
        .forEach(array => result.push(...array));
    return result;
}
