import * as React from 'react';
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ButtonBase, FormControl, FormControlLabel, Switch, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { StorageVars } from '../../../../../Constants/StorageConstants';


const useStyles = makeStyles(
	(theme: Theme) =>
		({
			formPrefix:
				{
					[theme.breakpoints.down('xs')]:
						{
							marginRight: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							marginRight: theme.spacing(2),
						},
				},
			labelRoot:
				{
					[theme.breakpoints.down('xs')]:
						{
							padding: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							padding: theme.spacing(2),
						},

					justifyContent: 'space-between',
					marginLeft: 0,
					marginRight: 0,
				},
			root:
				{
					flexGrow: 1,
				},
		}));

interface ShowOpenBillsSettingProps
{
	className?: string;
	style?: CSSProperties;
}

export const ShowOpenBillsSetting: FC<ShowOpenBillsSettingProps> =
	({
		 className,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const {
			storage,
		} = useContext(EntranceContextRef);

		const [showOpenBills, setShowOpenBills] = useState<boolean>();

		useEffect(
			() =>
			{
				storage.get(StorageVars.ManagerShowOpenBills)
					.then(value => setShowOpenBills(value === 'true'));
			},
			[storage]);

		useEffect(
			() =>
			{
				if (showOpenBills !== undefined)
					storage.set(StorageVars.ManagerShowOpenBills, showOpenBills ? 'true' : 'false').then();
			},
			[showOpenBills, storage]);

		const toggleShowOpenBills = useCallback(() => setShowOpenBills(!showOpenBills), [showOpenBills]);

		const formLabel = useMemo(
			() =>
			{
				if (showOpenBills !== undefined)
				{
					return <FormControlLabel
						classes={{
							root: classes.labelRoot,
						}}
						control={
							<Switch
								checked={showOpenBills}
								onChange={toggleShowOpenBills}
								value={showOpenBills}
							/>
						}
						label="Show open bills:"
						labelPlacement="start"
					/>;
				}
				else
				{
					return null;
				}
			},
			[classes.labelRoot, showOpenBills, toggleShowOpenBills]);

		return <ButtonBase
			className={className}
			style={style}
		>
			<FormControl
				classes={{
					root: classes.root,
				}}
			>
				{formLabel}
			</FormControl>
		</ButtonBase>;
	};