import Decimal from 'decimal.js';
import { toSeconds } from 'iso8601-duration';
import { IObservableArray, makeAutoObservable, observable, ObservableMap } from 'mobx';
import { v4 as uuid } from 'uuid';
import { Announcement } from '../../Api/Business/Announcement';
import { Business } from '../../Api/Business/Business';
import { OrderDestinationAddress, OrderDestinationAddressProfile } from '../../Api/Business/OrderDestinationAddress';
import { OrderRestriction } from '../../Api/Business/OrderRestriction';
import { OrderDestinationType, Place } from '../../Api/Business/Place';
import { Cart, CartProfile } from '../../Api/Order/Cart/Cart';
import { CartChange } from '../../Api/Order/Cart/CartChange';
import { ChangeQuantityCartChange } from '../../Api/Order/Cart/ChangeQuantityCartChange';
import { ChangeQuantityCartChangeVariant } from '../../Api/Order/Cart/ChangeQuantityCartChangeVariant';
import { CartChangeResponse, CartChangeResponseProfile } from '../../Api/Order/CartChangeResponse';
import { CouponMatch } from '../../Api/Order/Coupon/CouponMatch';
import { CurrentOrderCouponMatch } from '../../Api/Order/Coupon/CurrentOrderCouponMatch';
import { LoyaltyDiscount } from '../../Api/Order/Loyalty/LoyaltyDiscount';
import { LoyaltyDiscountMatch } from '../../Api/Order/Loyalty/LoyaltyDiscountMatch';
import { OrderCheckReport, OrderCheckReportProfile } from '../../Api/Order/OrderCheckReport';
import { OrderRestrictionConfiguration } from '../../Api/Order/OrderRestriction/OrderRestrictionConfiguration';
import { OrderRestrictionReport } from '../../Api/Order/OrderRestrictionReport';
import { OrderRestrictionType } from '../../Api/Order/OrderRestrictionType';
import { Currency } from '../../Api/Other/Currency';
import { PaymentMethodDescriptor } from '../../Api/Payment/PaymentMethodDescriptor';
import { Product } from '../../Api/Product/Product';
import { ProductCategory } from '../../Api/Product/ProductCategory';
import { ProductConfiguration } from '../../Api/Product/ProductConfiguration';
import { ProductFeatureAssignment } from '../../Api/Product/ProductFeatureAssignment';
import { ProductFeatureVariant } from '../../Api/Product/ProductFeatureVariant';
import { ProductFee } from '../../Api/Product/ProductFee';
import { TimeSchedule } from '../../Api/Util/time-schedule/TimeSchedule';
import { isTrueAtInCache } from '../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { extractRelevantTaxGroupSpecifications } from '../../Api/vat_group/util/extractRelevantTaxGroupSpecifications';
import { Bridge } from '../../Bridge/Bridge';
import { Localizer } from '../../Bridge/Localization/Localizer';
import { BusinessStore } from '../../Component/Page/Business/BusinessStore';
import { AdditiveTaxGroupKey } from '../../Component/UI/payment/price/context/AdditiveTaxGroupKey';
import { nonEmpty } from '../../lib/lang/nonEmpty';
import { fetch, post, postJson } from '../../Util/Api';
import { ClockService } from '../../Util/clock/ClockService';
import { debounce } from '../../Util/debounce';
import { divideExact } from '../../Util/decimal/divideExact';
import { CartChangeFailedException } from '../../Util/Exception/CartChangeFailedException';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { nonNullish } from '../../Util/nonNullish';
import { getProductCategoryAndAllParentProductCategoryIds } from '../../Util/Product/getProductCategoryAndAllParentProductCategoryIds';
import { transformISO8601ToDate } from '../../Util/Serialization/Transformers/transformISO8601ToDate';
import { StoredVariable } from '../../Util/StoredVariable';
import { CurrentPlaceService } from '../CurrentPlace/CurrentPlaceService';
import { getDeduplicatedCartLines } from './api/getDeduplicatedCartLines';

type LegalConsent = { tcVersion: number, ppVersion: number, date: Date };
type ProductFeeAmount = { productFee: ProductFee, amount: Decimal, };

const CURRENT_TC_VERSION = 1;
const CURRENT_PP_VERSION = 1;
const CONSENT_EXPIRY_MS = 1000 * 60 * 60 * 3; // 3 hours

const ComoCodePrefix = 'RedeemAsset.code.';
const ComoKeyPrefix = 'RedeemAsset.key.';

export class CurrentOrderService
{
	/*---------------------------------------------------------------*
	 *                         Dependencies                          *
	 *---------------------------------------------------------------*/

	private readonly clockService: ClockService;
	private readonly localizer: Localizer;
	private readonly bridge: Bridge;
	private readonly businessStore: BusinessStore;
	private readonly currentPlaceService: CurrentPlaceService;

	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	/**
	 * The {@link CurrentOrderService} of a direct order (when a single product is ordered directly,
	 * without the add-to-cart step)
	 */
	ownCartId: number | undefined;
	clientId: number | undefined;
	rootCategory: ProductCategory;
	private readonly quantityByConfiguration = observable.map<ProductConfiguration, number>();
	private readonly configurationById = observable.map<string, ProductConfiguration>();
	private readonly writeAheadLog = observable.array<ChangeRecord>();
	private dateOfLastLocallyAppliedCommittedCartChange: Date | undefined;
	private newestCartFromServer: { date: Date, cart: Cart } | undefined;
	restrictionReports = observable.array<OrderRestrictionReport>();
	private readonly presentBusinessAnnouncements = observable.array<Announcement>();
	productById: Map<number, Product>;
	productCategoryById: Map<number, ProductCategory>;
	productFeatureAssignmentById: Map<number, ProductFeatureAssignment>;
	productFeatureVariantById: Map<number, ProductFeatureVariant>;
	timeScheduleById: ObservableMap<number, TimeSchedule>;
	private readonly debouncedPersistAndValidateChanges: () => void;
	orderUuid: string;

	private static readonly DESTINATION_TYPE_STORAGE_KEY = 'destinationType';
	destinationType: StoredVariable<OrderDestinationType | undefined>;

	private static CLIENT_ADDRESS_STORAGE_KEY = 'clientAddress';
	destinationAddress: StoredVariable<OrderDestinationAddress | undefined>;

	activeLoyaltyDiscount: LoyaltyDiscountMatch | undefined = undefined;
	unconfirmedAssetKeys = observable.array<string>();

	addedCoupons = observable.array<CurrentOrderCouponMatch>();
	couponCode: string;

	public scheduledTime: Date | 'ASAP' | undefined;

	public comment: string | undefined;

	tip: Decimal | undefined;

	private static CLIENT_FIRST_NAME_STORAGE_KEY = 'clientName';
	clientFirstName: StoredVariable<string | undefined>;

	private static CLIENT_LAST_NAME_STORAGE_KEY = 'clientLastName';
	clientLastName: StoredVariable<string | undefined>;

	private static CLIENT_COMPANY_NAME_STORAGE_KEY = 'clientCompanyName';
	clientCompanyName: StoredVariable<string | undefined>;

	private static CLIENT_PHONE_NUMBER_STORAGE_KEY = 'clientPhoneNumber';
	clientPhoneNumber: StoredVariable<string | undefined>;

	private static CLIENT_EMAIL_STORAGE_KEY = 'clientEmail';
	clientEmail: StoredVariable<string | undefined>;

	private static LEGAL_CONSENT_STORAGE_KEY = 'legalConsent';
	legalConsent: StoredVariable<LegalConsent | undefined>;

	skipLegalConstentCheck: boolean;

	clearOrderOptionsAfterOrder: StoredVariable<boolean>;

	paymentMethod: PaymentMethodDescriptor | undefined;

	doCheckRestrictionsOnCartChange: boolean = false;

	public explicitConsentForOrderTrackerUserInput: boolean | undefined;

	public readonly externalShopperNotificationId?: string;

	public readonly externalShopperCardId?: string;

	orderProcessing: boolean;

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor(
		bridge: Bridge,
		businessStore: BusinessStore,
		currentPlaceService: CurrentPlaceService,
		cart: Cart | undefined,
		rootCategory: ProductCategory,
		onOpen: (product: Product) => void,
		presentBusinessAnnouncements: Announcement[],
		productById: Map<number, Product>,
		productCategoryById: Map<number, ProductCategory>,
		productFeatureAssignmentById: Map<number, ProductFeatureAssignment>,
		productFeatureVariantById: Map<number, ProductFeatureVariant>,
		timeScheduleById: ObservableMap<number, TimeSchedule>,
		clearOrderOptionsAfterOrder: StoredVariable<boolean>,
		externalShopperNotificationId?: string,
		externalShopperCardId?: string,
		skipLegalConsentCheck: boolean = false,
		comment?: string,
		destinationAddress?: OrderDestinationAddress,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.bridge = bridge;
		this.localizer = bridge.localizer;
		this.clockService = bridge.clockService;
		this.businessStore = businessStore;
		this.currentPlaceService = currentPlaceService;
		this.rootCategory = rootCategory;
		this.productById = productById;
		this.productCategoryById = productCategoryById;
		this.timeScheduleById = timeScheduleById;
		this.productFeatureAssignmentById = productFeatureAssignmentById;
		this.productFeatureVariantById = productFeatureVariantById;
		this.clearOrderOptionsAfterOrder = clearOrderOptionsAfterOrder;
		this.externalShopperNotificationId = externalShopperNotificationId;
		this.externalShopperCardId = externalShopperCardId;

		this.orderProcessing = false;

		this.debouncedPersistAndValidateChanges = debounce(
			() => this.persistAndValidateChanges(),
			300,
		);

		presentBusinessAnnouncements.forEach(announcement => this.presentBusinessAnnouncements.push(announcement));

		this.orderUuid = uuid();

		this.clientFirstName = new StoredVariable<string | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_FIRST_NAME_STORAGE_KEY,
			stringValue => stringValue,
			stringValue => stringValue,
		);
		this.clientFirstName.syncWithStorage();

		this.clientLastName = new StoredVariable<string | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_LAST_NAME_STORAGE_KEY,
			stringValue => stringValue,
			stringValue => stringValue,
		);
		this.clientLastName.syncWithStorage();

		this.clientCompanyName = new StoredVariable<string | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_COMPANY_NAME_STORAGE_KEY,
			stringValue => stringValue,
			stringValue => stringValue,
		);
		this.clientCompanyName.syncWithStorage();

		this.clientPhoneNumber = new StoredVariable<string | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_PHONE_NUMBER_STORAGE_KEY,
			stringValue => stringValue,
			stringValue => stringValue,
		);
		this.clientPhoneNumber.syncWithStorage();

		this.clientEmail = new StoredVariable<string | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_EMAIL_STORAGE_KEY,
			stringValue => stringValue,
			stringValue => stringValue,
		);
		this.clientEmail.syncWithStorage();

		this.destinationType = new StoredVariable<OrderDestinationType | undefined>(
			bridge.storage,
			CurrentOrderService.DESTINATION_TYPE_STORAGE_KEY,
			stringValue => stringValue as OrderDestinationType,
			deserializedValue => deserializedValue,
		);
		this.destinationType.syncWithStorage();

		this.destinationAddress = new StoredVariable<OrderDestinationAddress | undefined>(
			bridge.storage,
			CurrentOrderService.CLIENT_ADDRESS_STORAGE_KEY,
			stringValue => stringValue !== undefined
				?
				OrderDestinationAddressProfile.deserialize(JSON.parse(stringValue)) as OrderDestinationAddress
				:
				undefined,
			deserializedValue => deserializedValue !== undefined
				?
				JSON.stringify(deserializedValue)
				:
				undefined,
		);

		if (destinationAddress)
		{
			this.setDestinationAddress(destinationAddress);
		}
		else
		{
			this.destinationAddress.syncWithStorage();
		}

		this.legalConsent = new StoredVariable<LegalConsent | undefined>(
			bridge.storage,
			CurrentOrderService.LEGAL_CONSENT_STORAGE_KEY,
			stringValue => stringValue !== undefined
				?
				(() =>
				{
					const json = JSON.parse(stringValue);
					return {
						tcVersion: json.tcVersion,
						ppVersion: json.ppVersion,
						date: transformISO8601ToDate(json.date),
						persisted: json.persisted,
					} as LegalConsent;
				})()
				:
				undefined,
			deserializedValue => deserializedValue !== undefined
				?
				JSON.stringify(deserializedValue)
				:
				undefined,
		);
		this.legalConsent.syncWithStorage();
		this.skipLegalConstentCheck = skipLegalConsentCheck;

		this.comment = comment;

		this.couponCode = '';

		cart && this.updateCart(cart);

		this.initScheduledTime();

		this.checkOrder();
	}

	/*---------------------------------------------------------------*
	 *                        Initialization                         *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                           Computed                            *
	 *---------------------------------------------------------------*/

	public get initialized(): boolean
	{
		return this.clientFirstName.initialized
			&& this.clientLastName.initialized
			&& this.clientPhoneNumber.initialized
			&& this.clientEmail.initialized
			&& this.destinationType.initialized
			&& this.destinationAddress.initialized
			&& this.legalConsent.initialized
			&& this.clientCompanyName.initialized;
	}

	get business(): Business
	{
		return this.currentPlaceService.business!;
	}

	get currency(): Currency
	{
		return new Currency(this.business.productCurrencyCode);
	}

	get isAllowedToOrder(): boolean
	{
		return this.violatedReports.length === 0;
	}

	get quantity()
	{
		let quantity = 0;

		this.writeAheadCartContents.forEach(
			configurationQuantity =>
				quantity += configurationQuantity);

		return quantity;
	}

	get isEmpty()
	{
		return this.quantity === 0;
	}

	get effectiveDestinationType(): OrderDestinationType | undefined
	{
		const chosenValue = this.destinationType.value;
		const defaultValue = this.place!.defaultDestinationType;
		const possibleValues = this.currentPlaceService.supportedDestinationTypes;

		if (possibleValues.length === 1)
		{
			return possibleValues[0];
		}

		if (chosenValue !== undefined && possibleValues.indexOf(chosenValue) !== -1)
		{
			return chosenValue;
		}

		if (defaultValue !== undefined && possibleValues.indexOf(defaultValue) !== -1)
		{
			return defaultValue;
		}

		return undefined;
	}

	get hasLegalConsent(): boolean
	{
		if (this.skipLegalConstentCheck)
		{
			return true;
		}

		const legalConsent = this.legalConsent.value;
		if (legalConsent !== undefined)
		{
			if (legalConsent.ppVersion >= CURRENT_PP_VERSION)
			{
				if (legalConsent.tcVersion >= CURRENT_TC_VERSION)
				{
					const nowMillis = this.clockService.currentMinuteMillis;
					const consentMsAgo = nowMillis - legalConsent.date.getTime();
					if (consentMsAgo < CONSENT_EXPIRY_MS)
					{
						return true;
					}
				}
			}
		}
		return false;
	}

	public get hasExplicitConsentForOrderTracker(): boolean
	{
		return this.explicitConsentForOrderTrackerUserInput === true;
	}

	get writeAheadCartContents(): Map<ProductConfiguration, number>
	{
		const quantityByConfiguration = new Map<ProductConfiguration, number>();

		this.quantityByConfiguration.forEach(
			(quantity, configuration) =>
				quantityByConfiguration.set(configuration, quantity),
		);

		this.writeAheadLog
			.forEach(({cartChange}) =>
			{
				if (cartChange instanceof ChangeQuantityCartChange)
				{
					const configuration: ProductConfiguration = ((): ProductConfiguration =>
					{
						const existingConfiguration: ProductConfiguration | undefined = (() =>
						{
							for (const configuration of quantityByConfiguration.keys())
							{
								if (isCartLineDescribingProductConfiguration(
									{
										productId: cartChange.productId,
										variants: cartChange.variants,
									},
									configuration,
								))
								{
									return configuration;
								}
							}
							return undefined;
						})();
						return existingConfiguration
							??
							new ProductConfiguration(
								this.productById.get(cartChange.productId)!,
								cartChange.variants
									.map(cartChangeVariant => ({
										assignment: this.productFeatureAssignmentById.get(cartChangeVariant.assignmentId)!,
										variant: this.productFeatureVariantById.get(cartChangeVariant.variantId)!,
									})),
							);
					})();
					const oldQuantity = quantityByConfiguration.get(configuration) ?? 0;
					const newQuantity = oldQuantity + cartChange.diff;
					if (newQuantity > 0)
					{
						quantityByConfiguration.set(
							configuration,
							oldQuantity + cartChange.diff,
						);
					}
					else
					{
						quantityByConfiguration.delete(configuration);
					}
				}
			});

		return quantityByConfiguration;
	}

	public get needsPayment(): boolean
	{
		return this.price
			.greaterThan(0);
	}

	get currentOrderIsDeliveryOrder(): boolean
	{
		return this.effectiveDestinationType === 'ADDRESS';
	}

	get minimumOrderValueOverride(): Decimal | undefined
	{
		if (!this.currentOrderIsDeliveryOrder)
			return undefined;

		const configuration = this.currentPlaceService.place?.deliveryConfiguration;

		if (configuration === undefined)
			return undefined;

		const country = this.destinationAddress.value?.country;
		const postalCode = this.destinationAddress.value?.postalCode;
		return configuration.getConfiguredMinimumPriceAmountFromPostalCodeString(country, postalCode);
	}

	get minimumOrderValue(): Decimal
	{
		return this.minimumOrderValueOverride ?? this.place?.minimumOrderValue ?? new Decimal(0);
	}

	get activeProductFeeAmounts(): ProductFeeAmount[]
	{
		const pointInTime = this.clockService.currentMinute;

		return Array.from(this.feeAmountByFee.entries())
			.filter(([fee, amount]) =>
			{
				if (amount.gt(0))
				{
					if (fee.activationTimeScheduleId === undefined)
						return true;
					else
					{
						const timeSeries = this
							.timeScheduleById
							.get(fee.activationTimeScheduleId)
							?.booleanTimeSeries;

						return timeSeries === undefined
							? false
							: isTrueAtInCache(timeSeries, pointInTime);
					}
				}
				else
				{
					return false;
				}
			})
			.map<ProductFeeAmount>(([fee, amount]) => ({
				productFee: fee,
				amount,
			}));
	}

	get totalProductFeeAmount(): Decimal
	{
		return this
			.activeProductFeeAmounts
			.reduce(
				(result, currentFeeAmount) =>
					result.add(currentFeeAmount.amount),
				new Decimal(0),
			);
	}

	public get deliveryFeeAmount(): Decimal | undefined
	{
		if (!this.currentOrderIsDeliveryOrder)
			return undefined;

		const configuration = this.currentPlaceService.place?.deliveryConfiguration;

		if (configuration === undefined)
			return new Decimal(0);

		const country = this.destinationAddress.value?.country;
		const postalCode = this.destinationAddress.value?.postalCode;
		return configuration.getConfiguredPriceAmountFromPostalCodeString(
			country,
			postalCode,
			this.totalProductAmount
				.sub(this.totalDiscountAmount)
		);
	}

	public get takeoutOrEatHere()
	{
		return this.effectiveDestinationType === 'PLACE'
			|| this.effectiveDestinationType === 'PICKUP_POINT';
	}

	private get place(): Place | undefined
	{
		return this.currentPlaceService.place;
	}

	public get showFirstName(): boolean
	{
		return this.takeoutOrEatHere
			&& this.place?.isClientNameRequired === true;
	}

	public get showLastName(): boolean
	{
		return this.takeoutOrEatHere
			&& this.place?.lastNameField !== 'DISALLOWED';
	}

	public get showCompanyName(): boolean
	{
		return this.takeoutOrEatHere
			&& this.place?.companyNameField !== 'DISALLOWED';
	}

	public get showAddress(): boolean
	{
		return this.effectiveDestinationType === 'ADDRESS';
	}

	public get showPhoneNumberInDeliveryForm(): boolean
	{
		return this.place?.phoneNumberField !== 'DISALLOWED';
	}

	public get showEmail(): boolean
	{
		return this.place?.emailField !== 'DISALLOWED';
	}

	get showScheduler(): boolean
	{
		return this
			.place!
			.orderInFutureSupport !== 'DISALLOWED';
	}

	get showCommentForm(): boolean
	{
		return this
			.place!
			.commentField !== 'DISALLOWED';
	}

	public get showTipForm(): boolean
	{
		return this.supportsTip
			&& this.needsPayment
			&& this.paymentMethod?.id !== 'traditional';
	}

	public get showOrderTrackerConsentForm(): boolean
	{
		return this.place?.explicitConsentOrderTrackerRequired !== 'DISALLOWED'
			&& this.configurations.some(configuration => configuration.product.hasEmbeddableHtmlAfterOrder);
	}

	public get requireOrderTrackerConsent(): boolean
	{
		return this.place?.explicitConsentOrderTrackerRequired === 'REQUIRED'
			&& this.configurations.some(configuration => configuration.product.hasEmbeddableHtmlAfterOrder);
	}

	public get requiresPhoneNumberInSchedulerForm(): boolean
	{
		return this.effectiveHandoverDateSpec !== 'ASAP'
			&& this.effectiveHandoverDateSpec !== undefined
			&& this.currentPlaceService.smsRemindersEnabled;
	}

	public get supportsCouponCode(): boolean
	{
		return this.place?.couponCodeSupport === 'ALLOWED'
			|| this.place?.couponCodeSupport === 'REQUIRED';
	}

	public get effectiveHandoverDateSpec(): Date | 'ASAP' | undefined
	{
		return this.scheduledTime;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderScheduledFor(): string | null | undefined
	{
		if (this.showScheduler)
		{
			const scheduledFor = this.effectiveHandoverDateSpec;

			return scheduledFor instanceof Date
				? scheduledFor.toISOString()
				: undefined;
		}
		else
		{
			return undefined;
		}
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderComment(): string | undefined
	{
		return this.showCommentForm
			? this.comment
			: undefined;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderTip(): Decimal | undefined
	{
		return this.showTipForm
			? this.tip
			: undefined;
	}

	public get supportsTip(): boolean
	{
		return this.place?.tipField === 'ALLOWED' || this.requiresTip;
	}

	public get requiresTip(): boolean
	{
		return this.place?.tipField === 'REQUIRED';
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderCouponCodes(): string[] | undefined
	{
		return this.supportsCouponCode
			? this.allCouponCodes
			: undefined;
	}

	get orderComoAssetKeys(): string[] | undefined
	{
		return this.businessStore.loyaltyIntegration === 'COMO'
			? this.allAssetKeys
			: undefined;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderClientPhoneNumber(): string | undefined
	{
		return (this.showPhoneNumberInDeliveryForm || this.requiresPhoneNumberInSchedulerForm)
			? this.clientPhoneNumber.value
			: undefined;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderClientEmail(): string | undefined
	{
		return this.showEmail
			? this.clientEmail.value
			: undefined;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderDestinationAddress(): OrderDestinationAddress | undefined
	{
		return this.showAddress
			? this.destinationAddress.value
			: undefined;
	}

	/**
	 * The value that is appended to the {@link Order}. This is undefined if no value is explicitly filled in
	 * for this {@link Order} by the customer.
	 */
	get orderClientName(): string | undefined
	{
		const effectivePersonName = (() =>
		{
			if (this.effectiveDestinationType === 'ADDRESS')
				return this.destinationAddress.value?.name;
			else
			{
				const firstName = this.showFirstName
					? this.clientFirstName.value
					: undefined;
				const lastName = this.showLastName
					? this.clientLastName.value
					: undefined;

				return (firstName ?? '')
					+ (nonEmpty(firstName, lastName) ? ' ' : '')
					+ (lastName ?? '');
			}
		})();

		const effectiveCompanyName = this.showCompanyName
			?
			this.clientCompanyName.value
			:
			(
				this.effectiveDestinationType === 'ADDRESS'
					? this.destinationAddress.value?.companyName
					: undefined
			);

		const combinedName = effectivePersonName
			+ (nonEmpty(effectivePersonName, effectiveCompanyName) ? ' (' : '')
			+ (nonEmpty(effectiveCompanyName) ? effectiveCompanyName : '')
			+ (nonEmpty(effectivePersonName, effectiveCompanyName) ? ')' : '');
		return nonEmpty(combinedName)
			? combinedName
			: undefined;
	}

	get configurations(): ProductConfiguration[]
	{
		const configurations: IObservableArray<ProductConfiguration> = observable.array<ProductConfiguration>();

		this.writeAheadCartContents.forEach(
			(quantity, configuration) =>
				configurations.push(configuration));

		return configurations
			.slice()
			.sort((a, b) => a.id.localeCompare(b.id));
	}

	get quantityByProduct()
	{
		const quantityByProduct = observable.map<Product, number>();

		this.writeAheadCartContents.forEach(
			(quantity, configuration) =>
			{
				let totalQuantity = quantity;

				if (quantityByProduct.has(configuration.product))
				{
					totalQuantity += quantityByProduct.get(configuration.product) || 0;
				}

				quantityByProduct.set(
					configuration.product,
					totalQuantity,
				);
			});

		return quantityByProduct;
	}

	get activeMenuCardIds(): number[]
	{
		const menuCardIds = this.place?.menuCardIds ?? [];

		const placeSessionMenuCardId = this.businessStore.placeSession?.menuCard?.id;

		if (placeSessionMenuCardId === undefined)
			return observable.array(menuCardIds);
		else
			return observable.array([...menuCardIds, placeSessionMenuCardId]);
	}

	get relevantReports(): OrderRestrictionReport[]
	{
		const menuCardIds = this.activeMenuCardIds;

		return this.restrictionReports
			.filter(({configuration}) =>
				isPlaceIncludedInSelection(
					this.place,
					configuration.includedPlaceIds ? new Set(configuration.includedPlaceIds) : undefined,
					configuration.excludedPlaceIds ? new Set(configuration.excludedPlaceIds) : undefined,
				),
			)
			.filter(({configuration}) =>
			{
				const includedMenuCardIds = configuration.includedMenuCardIds ? new Set(configuration.includedMenuCardIds) : undefined;
				const excludedMenuCardIds = configuration.excludedMenuCardIds ? new Set(configuration.excludedMenuCardIds) : undefined;

				if (includedMenuCardIds === undefined && excludedMenuCardIds === undefined)
				{
					return true;
				}
				else if (isMenuCardIncludedInSelection(undefined, includedMenuCardIds, excludedMenuCardIds))
				{

					return true;
				}
				else
				{
					return menuCardIds
						.some(menuCardId =>
							isMenuCardIncludedInSelection(
								menuCardId,
								includedMenuCardIds,
								excludedMenuCardIds,
							),
						);
				}
			});
	}

	get violatedReports(): OrderRestrictionReport[]
	{
		return this.restrictionReports
			.filter(
				report =>
					!report.isPassed);
	}

	get announcements(): Announcement[]
	{
		const messageSet = new Set<string>();

		const restrictionAnnouncements = this.violatedReports
			.filter(
				report =>
				{
					if (messageSet.has(report.message))
					{
						return false;
					}
					else
					{
						messageSet.add(report.message);

						return true;
					}
				},
			)
			.map(
				report =>
					new Announcement(
						undefined,
						'danger',
						report.message,
						true,
						false,
						true,
					),
			);

		if (this.business.isServicePaused)
		{
			return [
				new Announcement(
					undefined,
					'danger',
					this.localizer.translate('Business-Service-Pause-Message-Default'),
					true,
					true,
					true,
				),
				...restrictionAnnouncements,
			];
		}
		else
		{
			return restrictionAnnouncements;
		}
	}

	get orderAnnouncementsRequiringUserAcknowledgement(): Announcement[]
	{
		const allCategories = Array.from(this.productCategoryById.values());

		const productsInOrder = this.configurations.map(configuration => configuration.product);
		const productIdsInOrder = productsInOrder.map(product => product.id);

		const productCategoryIdsRelatedToOrder = productsInOrder
			.flatMap(product => getProductCategoryAndAllParentProductCategoryIds(product.category, allCategories))
			.filter((id, idx, array) => array.indexOf(id) === idx);

		return this.presentBusinessAnnouncements
			.filter(announcement => announcement.requiresUserAcknowledgement)
			.filter(announcement =>
			{
				const isGeneralAnnouncement = !announcement.isAssignedToProduct && !announcement.isAssignedToProductCategory;
				const isRelevantProductAnnouncement = announcement.isAssignedToProduct && announcement.productIds.some(id => productIdsInOrder.includes(id));
				const isRelevantProductCategoryAnnouncement = announcement.isAssignedToProductCategory && announcement.productCategoryIds.some(id => productCategoryIdsRelatedToOrder.includes(id));

				return isGeneralAnnouncement || isRelevantProductAnnouncement || isRelevantProductCategoryAnnouncement;
			});
	}

	get canOrder(): boolean
	{
		return this.violatedReports.length === 0 && !this.business.isServicePaused;
	}

	get hasActiveLoyaltyDiscount(): boolean
	{
		return this.activeLoyaltyDiscount !== undefined;
	}

	get addedLoyaltyCoupons(): LoyaltyDiscount[]
	{
		return this
			.activeLoyaltyDiscount
			?.discounts
			.filter(l => l.externalId !== undefined && l.externalId.startsWith(ComoCodePrefix)) ?? [];
	}

	get addedLoyaltyRewards(): LoyaltyDiscount[]
	{
		return this
			.activeLoyaltyDiscount
			?.discounts
			.filter(l => l.externalId !== undefined && l.externalId.startsWith(ComoKeyPrefix)) ?? [];
	}

	get hasActiveCoupons(): boolean
	{
		return this.addedCoupons.length > 0 || this.addedLoyaltyCoupons.length > 0;
	}

	get totalProductAmount(): Decimal
	{
		return this
			.configurations
			.reduce(
				(result, configuration) =>
					result.add(
						this.getPrice(configuration),
					),
				new Decimal(0),
			);
	}

	get totalDiscountAmount(): Decimal
	{
		return this
			.configurations
			.reduce(
				(result, configuration) =>
					result.add(
						this.getDiscount(configuration),
					),
				new Decimal(0),
			);
	}

	get price(): Decimal
	{
		return Array.from(this.additiveTaxAmounts.values())
			.reduce(
				(subTotal, taxAmount) =>
					subTotal.add(taxAmount.toDecimalPlaces(this.currency.decimalPlaces)),
				this
					.totalProductAmount
					.sub(this.totalDiscountAmount)
					.add(this.totalProductFeeAmount)
					.add(this.deliveryFeeAmount ?? 0),
			);
	}

	get confirmedCouponCodes(): string[]
	{
		return [
			...this.addedCoupons.map(d => d.code),
			...this.addedLoyaltyCoupons.map(d => d.externalId.replace(ComoCodePrefix, ''))
		];
	}

	get allCouponCodes(): string[]
	{
		const allCouponCodes = [...this.confirmedCouponCodes];
		if (this.couponCode !== '')
		{
			allCouponCodes.push(this.couponCode);
		}
		return allCouponCodes;
	}

	get confirmedAssetKeys(): string[]
	{
		return this.addedLoyaltyRewards.map(d => d.externalId.replace(ComoKeyPrefix, ''));
	}

	get allAssetKeys(): string[]
	{
		return [...this.confirmedAssetKeys, ...this.unconfirmedAssetKeys];
	}

	get noCartChangesWithoutTimestampInWriteAheadLog(): boolean
	{
		return this.writeAheadLog
			.filter(changeRecord => changeRecord.date === undefined)
			.length === 0;
	}

	get maximumPreparationTime(): Duration | undefined
	{
		const preparationTimes = this
			.configurations
			.map(configuration => configuration.product.preparationTime)
			.filter(nonNullish);

		if (preparationTimes.length > 0)
			return preparationTimes
				.sort(
					(a, b) =>
						toSeconds(b) - toSeconds(a),
				)[0];
		else
			return undefined;
	}

	get baseLineRoutingIds(): number[]
	{
		// sort routing ids such that the routings contributing the highest amounts are first in the list
		return Array
			.from(
				this
					.configurations
					.filter(configuration => configuration.product.routing?.id !== undefined)
					.reduce(
						(result, configuration) =>
						{
							const routingId = configuration.product.routing!.id;

							if (!result.has(routingId))
								result.set(routingId, new Decimal(0));

							const amount = this.getPrice(configuration).sub(this.getDiscount(configuration));

							result.set(
								routingId,
								result.get(routingId)!.add(amount),
							);

							return result;
						},
						new Map<number, Decimal>(),
					)
					.entries(),
			)
			.sort(([routingId1, amount1], [routingId2, amount2]) =>
			{
				const result = amount2.comparedTo(amount1);

				if (result === 0)
					return routingId1 - routingId2;
				else
					return result;
			})
			.map(([routingId]) => routingId);
	}

	get additiveTaxAmounts(): Map<AdditiveTaxGroupKey, Decimal>
	{
		const baseLineRoutingIds = this.baseLineRoutingIds;

		const rawAdditiveTaxAmountPerTaxGroupId = this
			.configurations
			.reduce(
				(rawAdditiveTaxAmountPerTaxGroupId, configuration) =>
				{
					if (configuration.product.vatGroup?.type === 'Additive')
					{
						const routingId = configuration.product.routing?.id;
						extractRelevantTaxGroupSpecifications(configuration.product.vatGroup)
							.forEach(({id, percentage}) =>
							{
								const additiveTaxAmount = computeAdditiveTaxAmount(percentage, this.getPrice(configuration).sub(this.getDiscount(configuration)));

								const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

								if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
								{
									rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
								}

								rawAdditiveTaxAmountPerTaxGroupId.set(
									key,
									rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(additiveTaxAmount),
								);
							});
					}

					return rawAdditiveTaxAmountPerTaxGroupId;
				},
				new Map<AdditiveTaxGroupKey, Decimal>(),
			);

		this.activeProductFeeAmounts
			.forEach((feeAmount) =>
			{
				if (feeAmount.productFee.vatGroup?.type === 'Additive')
				{
					const routingId = feeAmount.productFee.routing?.id;
					extractRelevantTaxGroupSpecifications(feeAmount.productFee.vatGroup)
						.forEach(({id, percentage}) =>
						{
							const additiveTaxAmount = computeAdditiveTaxAmount(percentage, feeAmount.amount);

							const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

							if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
							{
								rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
							}

							rawAdditiveTaxAmountPerTaxGroupId.set(
								key,
								rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(additiveTaxAmount),
							);
						});
				}
			});

		if (this.currentPlaceService.place?.deliveryFeeVatGroup?.type === 'Additive')
		{
			const relevantRoutingIds = this.currentPlaceService.place.deliveryFeeRouting === undefined
				? baseLineRoutingIds.length === 0
					? [undefined]
					: baseLineRoutingIds
				: [this.currentPlaceService.place.deliveryFeeRouting.id];

			const deliveryFeeAmountPerRouting = divideExact(
				this.deliveryFeeAmount ?? new Decimal(0),
				relevantRoutingIds.length,
				this.currency.decimalPlaces,
			);

			extractRelevantTaxGroupSpecifications(this.currentPlaceService.place.deliveryFeeVatGroup)
				.forEach(({id, percentage}) =>
				{
					relevantRoutingIds.forEach((routingId, index) =>
					{
						const additiveTaxAmount = computeAdditiveTaxAmount(percentage, deliveryFeeAmountPerRouting[index]);

						const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

						if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
						{
							rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
						}

						rawAdditiveTaxAmountPerTaxGroupId.set(
							key,
							rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(additiveTaxAmount),
						);
					});
				});
		}

		if (this.business.tipVatGroup?.type === 'Additive')
		{
			const relevantRoutingIds = this.business.tipRouting === undefined
				? baseLineRoutingIds.length === 0
					? [undefined]
					: baseLineRoutingIds
				: [this.business.tipRouting.id];

			const tipAmountPerRouting = divideExact(
				this.tip ?? new Decimal(0),
				relevantRoutingIds.length,
				this.currency.decimalPlaces,
			);

			extractRelevantTaxGroupSpecifications(this.business.tipVatGroup)
				.forEach(({id, percentage}) =>
				{
					relevantRoutingIds.forEach((routingId, index) =>
					{
						const additiveTaxAmount = computeAdditiveTaxAmount(percentage, tipAmountPerRouting[index]);

						const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

						if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
						{
							rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
						}

						rawAdditiveTaxAmountPerTaxGroupId.set(
							key,
							rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(additiveTaxAmount),
						);
					});
				});
		}

		return rawAdditiveTaxAmountPerTaxGroupId;
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                          Public logic                         *
	 *---------------------------------------------------------------*/

	/**
	 * @param productConfiguration
	 * @return {@code true} if the {@link Business} has enough stock to be able to fulfill the cart line associated
	 * with this {@link ProductConfiguration}.
	 */
	public isInStock(productConfiguration: ProductConfiguration): boolean
	{
		const quantityInCart = this.writeAheadCartContents.get(productConfiguration);

		if (quantityInCart === undefined)
		{
			throw new IllegalStateException('Can only ask for configuration that is in Cart');
		}

		{
			const quantityInStock = productConfiguration.product.quantity;
			if (quantityInStock !== undefined && quantityInStock < quantityInCart)
			{
				return false;
			}
		}

		for (let variantConfiguration of productConfiguration.variantConfigurations)
		{
			const quantityInStock = variantConfiguration.variant.quantity;
			if (quantityInStock !== undefined && quantityInStock < quantityInCart)
			{
				return false;
			}
		}

		return true;
	}

	public setAssetKey(assetKey: string)
	{
		this.unconfirmedAssetKeys.push(assetKey);
	}

	public setCouponCode(couponCode: string)
	{
		this.couponCode = couponCode;
	}

	initScheduledTime(): void
	{
		this.scheduledTime = undefined;
	}

	public setHasLegalConsent(hasLegalConsent: boolean): void
	{
		if (!hasLegalConsent && this.hasLegalConsent)
		{
			this.legalConsent.set(undefined);
		}
		else if (hasLegalConsent)
		{
			this.legalConsent.set({
				tcVersion: CURRENT_TC_VERSION,
				ppVersion: CURRENT_PP_VERSION,
				date: new Date(),
			});
		}
	}

	public setExplicitConsentForOrderTracker(explicitConsentOrderTracker: boolean): void
	{
		this.explicitConsentForOrderTrackerUserInput = explicitConsentOrderTracker;
	}

	/**
	 * Returns the first order restriction report matching the type
	 * @param type
	 */
	public getOrderRestrictionReport(type: OrderRestrictionType): OrderRestrictionReport | undefined
	{
		return this.relevantReports.find(report => report.type === type);
	}

	/**
	 * Returns the quantity of items relevant for this order restriction configuration
	 * @param configuration
	 */
	public getRelevantQuantity(configuration: OrderRestrictionConfiguration): number
	{
		let quantity = 0;

		this.writeAheadCartContents.forEach(
			(configurationQuantity, productConfiguration) =>
			{
				const isIncluded = isCategoryIncludedInSelection(
							productConfiguration.product.category,
							configuration.includedCategoryIds ? new Set(configuration.includedCategoryIds) : undefined,
							configuration.excludedCategoryIds ? new Set(configuration.excludedCategoryIds) : undefined,
						) &&
						isMenuCardIncludedInSelection(
							productConfiguration.product.menuCardId,
							configuration.includedMenuCardIds ? new Set(configuration.includedMenuCardIds) : undefined,
							configuration.excludedMenuCardIds ? new Set(configuration.excludedMenuCardIds) : undefined,
						)
				;

				if (isIncluded)
					quantity += configurationQuantity;
			});

		return quantity;
	}

	/*---------------------------------------------------------------*
	 *                         Private logic                         *
	 *---------------------------------------------------------------*/

	// -------------------------- Actions ---------------------------

	processNewCartFromServer(cart: Cart, updateTimestamp: Date)
	{
		if (!this.orderProcessing)
		{
			if (this.newestCartFromServer === undefined || this.newestCartFromServer.date < updateTimestamp)
			{
				this.newestCartFromServer = {date: updateTimestamp, cart};
			}

			if (this.noCartChangesWithoutTimestampInWriteAheadLog)
			{
				this.updateCart(
					this.newestCartFromServer.cart,
					this.newestCartFromServer.date,
				);
			}
		}
	}

	private updateCart(cart: Cart, updateTimestamp?: Date)
	{
		if (!this.orderProcessing)
		{
			const commitTimestampOfNewCart = updateTimestamp ?? cart.dateLastChanged;
			this.setOwnCartId(cart.id);
			this.setClientId(cart.clientId);

			Array.from(this.configurationById.values())
				.forEach(configuration =>
				{
					if (!cart.lines.find(cartLine => isCartLineDescribingProductConfiguration(cartLine, configuration)))
					{
						this.configurationById.delete(configuration.id);
						this.quantityByConfiguration.delete(configuration);
					}
				});

			if (this.dateOfLastLocallyAppliedCommittedCartChange !== undefined
				&& this.dateOfLastLocallyAppliedCommittedCartChange > commitTimestampOfNewCart)
			{
				return;
			}
			this.dateOfLastLocallyAppliedCommittedCartChange = commitTimestampOfNewCart;
			this.truncateWriteAheadLog();

			getDeduplicatedCartLines(cart.lines)
				.forEach(cartLine =>
					{
						const productConfiguration = (() =>
						{
							for (let configuration of this.configurationById.values())
							{
								if (isCartLineDescribingProductConfiguration(cartLine, configuration))
								{
									return configuration;
								}
							}
						})();

						if (productConfiguration)
						{
							this.quantityByConfiguration.set(productConfiguration, cartLine.quantity);
						}
						else
						{
							let product = this.productById.get(cartLine.productId);
							if (product !== undefined)
							{
								this.addToStoreMemory(
									new ProductConfiguration(
										product,
										cartLine.variants
											.map(cartLineVariant => ({
												variant: this.productFeatureVariantById
													.get(cartLineVariant.variantId)!,
												assignment: this.productFeatureAssignmentById
													.get(cartLineVariant.assignmentId)!,
											})),
									),
									cartLine.quantity,
								);
							}
						}
					},
				);

			this.checkOrderIfNecessary();
		}
	}

	truncateWriteAheadLog()
	{
		this.writeAheadLog.replace(
			this.writeAheadLog.slice()
				.filter(changeRecord =>
				{
					return changeRecord.date === undefined
						|| (
							this.dateOfLastLocallyAppliedCommittedCartChange !== undefined
							&& changeRecord.date > this.dateOfLastLocallyAppliedCommittedCartChange
						);
				}),
		);
	}

	add(
		productConfiguration: ProductConfiguration,
		diffInQuantity: number,
	): void
	{
		const existingChangeRecord = this.writeAheadLog
			.filter(changeRecord => !changeRecord.sentToServer)
			.find(changeRecord => changeRecord.cartChange instanceof ChangeQuantityCartChange
				&& isCartLineDescribingProductConfiguration(
					{
						productId: changeRecord.cartChange.productId,
						variants: changeRecord.cartChange.variants,
					},
					productConfiguration,
				),
			);
		if (existingChangeRecord)
		{
			(existingChangeRecord.cartChange as ChangeQuantityCartChange).setDiff(
				(existingChangeRecord.cartChange as ChangeQuantityCartChange).diff + diffInQuantity,
			);
		}
		else
		{
			if (this.ownCartId !== undefined)
			{
				const change = new ChangeQuantityCartChange(
					this.ownCartId,
					{
						productId: productConfiguration.product.id,
						variants: productConfiguration.variantConfigurations
							.map(productConfigurationVariant => new ChangeQuantityCartChangeVariant(
								productConfigurationVariant.variant.id,
								productConfigurationVariant.assignment.id,
							)),
					},
					diffInQuantity,
				);

				this.writeAheadLog.push(new ChangeRecord(change, undefined));
			}
		}
		this.debouncedPersistAndValidateChanges();
	}

	persistAndValidateChanges(): void
	{
		Promise
			.all(
				this
					.writeAheadLog
					.filter(changeRecord => !changeRecord.sentToServer)
					.map(changeRecord => this.persistAndValidateChange(changeRecord)),
			)
			.then(() =>
			{
				if (this.noCartChangesWithoutTimestampInWriteAheadLog && this.newestCartFromServer)
				{
					this.updateCart(
						this.newestCartFromServer.cart,
						this.newestCartFromServer.date,
					);
				}
			})
			.catch(error =>
			{
				if (error?.name === 'CartChangeFailedException')
					fetch('/client/business/cart', undefined, CartProfile)
						.then(cart => this.updateCart(cart));
			});
	}

	addToStoreMemory(
		productConfiguration: ProductConfiguration,
		diffInQuantity: number,
	): void
	{
		const usedProductConfiguration: ProductConfiguration = (() =>
		{
			if (this.configurationById.has(productConfiguration.id))
			{
				return this.configurationById.get(productConfiguration.id)!;
			}
			else
			{
				this.configurationById.set(productConfiguration.id, productConfiguration);
				this.quantityByConfiguration.set(productConfiguration, 0);
				return productConfiguration;
			}
		})();


		const newQuantity = (() =>
		{
			let quantity: number =
				this.quantityByConfiguration.has(usedProductConfiguration)
					?
					this.quantityByConfiguration.get(usedProductConfiguration)!
					:
					0;
			quantity += diffInQuantity;
			return quantity;
		})();

		if (newQuantity <= 0)
		{
			this.quantityByConfiguration.delete(usedProductConfiguration);
			this.configurationById.delete(usedProductConfiguration.id);
		}
		else
		{
			this.quantityByConfiguration.set(usedProductConfiguration, newQuantity);
		}
	}

	clearOrderLines(): void
	{
		this.quantityByConfiguration.clear();
	}

	clearOrder()
	{
		this.clearOrderLines();
		this.orderUuid = uuid();
		this.couponCode = '';
		this.unconfirmedAssetKeys.clear();
		this.activeLoyaltyDiscount = undefined;
		this.addedCoupons.clear();
		this.comment = undefined;
		this.tip = undefined;
		this.initScheduledTime();
		this.setHasLegalConsent(false);
		this.setExplicitConsentForOrderTracker(false);
	}

	clearPossiblyReusableSettings()
	{
		this.destinationType.set(undefined);
		this.clientFirstName.set(undefined);
		this.clientLastName.set(undefined);
		this.clientPhoneNumber.set(undefined);
		this.clientCompanyName.set(undefined);
		this.destinationAddress.set(undefined);
		this.clientEmail.set(undefined);
	}

	/**
	 * After placing an {@link Order} some variables in {@link CurrentOrderService}
	 * have to be reset. This method is for doing that.
	 */
	cleanAfterOrder()
	{
		this.clearOrder();
		if (this.clearOrderOptionsAfterOrder.value)
		{
			this.clearPossiblyReusableSettings();
		}

		return this.checkOrderIfNecessary();
	}

	setRestrictionReports(restrictionReports: OrderRestrictionReport[]): void
	{
		// this.restrictionReports.replace(restrictionReports); // TODO Mobx currently does not support this

		this.restrictionReports.clear();

		restrictionReports.forEach(
			report =>
				this.restrictionReports.push(report));
	}

	setActiveLoyaltyDiscount(loyaltyDiscount: LoyaltyDiscountMatch | undefined): void
	{
		this.activeLoyaltyDiscount = loyaltyDiscount;

		if (loyaltyDiscount !== undefined)
		{
			if (loyaltyDiscount.discounts.some(discount => discount.externalId.startsWith(ComoCodePrefix)))
				this.couponCode = '';

			loyaltyDiscount.discounts
				.filter(discount => discount.externalId.startsWith(ComoKeyPrefix))
				.map(discount => discount.externalId.replace(ComoKeyPrefix, ''))
				.forEach(id => this.unconfirmedAssetKeys.remove(id));
		}
	}

	setActiveCoupons(couponMatches: CouponMatch[]): void
	{
		this.addedCoupons.clear();

		for (let couponMatch of couponMatches)
		{
			let code = couponMatch.code;
			let couponDescriptor = couponMatch.coupon;

			this.addedCoupons.push(
				{
					code: code,
					discount: couponMatch.discount,
					coupon: couponDescriptor.coupon,
					lines: couponMatch.lines,
				},
			);

			this.couponCode = '';
		}
	}

	setOwnCartId(ownCartId: number)
	{
		this.ownCartId = ownCartId;
	}

	setClientId(clientId: number)
	{
		this.clientId = clientId;
	}

	setScheduledTime(scheduledTime: Date | 'ASAP' | undefined): void
	{
		this.scheduledTime = scheduledTime;
	}

	setClientPhoneNumber(clientPhoneNumber: string | undefined): void
	{
		this.clientPhoneNumber.set(clientPhoneNumber);
	}

	public setComment(comment: string | undefined)
	{
		this.comment = comment;
	}

	public setTip(tip?: Decimal)
	{
		this.tip = tip;
	}

	public setDestinationType(destinationType: OrderDestinationType)
	{
		this.destinationType.set(destinationType);
	}

	public setDestinationAddress(destinationAddress: OrderDestinationAddress)
	{
		this.destinationAddress.set(destinationAddress);
	}

	// ------------------------ Public logic ------------------------

	getQuantityByProduct(product: Product)
	{
		if (this.quantityByProduct)
		{
			return this.quantityByProduct.get(product) || 0;
		}
		else
		{
			return 0;
		}
	}

	getPrice(configuration: ProductConfiguration)
	{
		const quantity = this.writeAheadCartContents.get(configuration);
		if (quantity === undefined)
		{
			throw new IllegalStateException();
		}

		return this.getUnitPrice(configuration).mul(quantity);
	}

	getUnitPrice(configuration: ProductConfiguration)
	{
		return configuration.price;
	}

	getDiscount(configuration: ProductConfiguration)
	{
		const quantity = this.writeAheadCartContents.get(configuration);
		if (quantity === undefined)
		{
			throw new IllegalStateException();
		}
		const configurationId = configuration.id;

		if (this.activeLoyaltyDiscount === undefined)
		{
			return this.addedCoupons
				.flatMap(coupon => coupon.lines)
				.filter(couponLineMatch => couponLineMatch.signatureId === configurationId)
				.map(couponLineMatch => new Decimal(couponLineMatch.discount).mul(Math.min(couponLineMatch.quantity, quantity)))
				.reduce(
					(subTotal, discount) =>
						subTotal.add(discount),
					new Decimal(0),
				);
		}
		else
		{
			return this
				.activeLoyaltyDiscount
				.lines
				.filter(discountLine => discountLine.signatureId === configurationId)
				.map(discountLine => new Decimal(discountLine.discount).mul(Math.min(discountLine.quantity, quantity)))
				.reduce(
					(subTotal, discount) =>
						subTotal.add(discount),
					new Decimal(0),
				);
		}
	}

	getUnitDiscount(configuration: ProductConfiguration)
	{
		const configurationId = configuration.id;

		if (this.activeLoyaltyDiscount === undefined)
		{
			return this.addedCoupons
				.flatMap(coupon => coupon.lines)
				.filter(couponLineMatch => couponLineMatch.signatureId === configurationId)
				.map(couponLineMatch => couponLineMatch.discount)
				.reduce(
					(subTotal, discount) =>
						subTotal.add(discount),
					new Decimal(0),
				);
		}
		else
		{
			return this
				.activeLoyaltyDiscount
				.lines
				.filter(discountLine => discountLine.signatureId === configurationId)
				.map(discountLine => discountLine.discount)
				.reduce(
					(subTotal, discount) =>
						subTotal.add(discount),
					new Decimal(0),
				);
		}
	}

	private get scheduledOrderLimitRestrictionOnThisPlace(): OrderRestriction | undefined
	{
		if (this.place === undefined)
			return undefined;

		const scheduledOrderLimitRestrictions = this.business.orderRestrictions
			.filter(orderRestriction =>
			{
				if (orderRestriction.type !== 'ScheduledOrderLimit')
					return false;
				const {
					includedPlaceIds,
					excludedPlaceIds,
				} = orderRestriction.configuration;
				const placeIncluded = includedPlaceIds !== undefined
					? includedPlaceIds.indexOf(this.place!.id) !== -1
					: true;
				const placeExcluded = excludedPlaceIds !== undefined
					? excludedPlaceIds.indexOf(this.place!.id) !== -1
					: false;
				return placeIncluded && !placeExcluded;
			});
		return scheduledOrderLimitRestrictions.length > 0
			? scheduledOrderLimitRestrictions[0]
			: undefined;
	}

	private get hasScheduledOrderLimitOnThisPlace(): boolean
	{
		return this.scheduledOrderLimitRestrictionOnThisPlace !== undefined;
	}

	/**
	 * Checks order with backend if there are no coupon codes. Order restriction report is already validated
	 * during cart-change endpoints (in case the order builder is visible).
	 */
	public checkOrderIfNecessary(): Promise<void>
	{
		// It might be that websocket update notifications are received during the order placement
		if (this.orderProcessing)
		{
			return Promise.resolve();
		}
			// Order restrictions are already validated using the cart-change endpoint,
			// but the coupons are not validated in this endpoint
		// So we only need to call the validate endpoint if we have coupons defined
		else if (
			this.allCouponCodes.length === 0 &&
			this.businessStore.loyaltyIntegration !== 'COMO' &&
			!(this.showScheduler && this.hasScheduledOrderLimitOnThisPlace)
		)
		{
			return Promise.resolve();
		}
		else
		{
			return this.checkOrder();
		}
	}

	/**
	 * Check order with backend: validate it and for {@link Coupon} code matches
	 */
	public async checkOrder(): Promise<void>
	{
		const orderCheckReport: OrderCheckReport = await postJson(
			'/client/business/product-order/check',
			{
				coupon_codes: this.allCouponCodes,
				como_asset_keys: this.allAssetKeys,
			},
			OrderCheckReportProfile,
			{
				lines: this
					.configurations
					.map(configuration => ({
						type: 'product',
						product_id: configuration.product.id,
						quantity: this.writeAheadCartContents.get(configuration),
						product_variants: configuration
							.variantConfigurations
							.map(variant => ({
								product_feature_variant_id: variant.variant.id,
								product_feature_assignment_id: variant.assignment.id,
							})),
					})),
				scheduledFor: this.orderScheduledFor,
			},
		);

		this.setRestrictionReports(orderCheckReport.restrictions);
		this.setActiveCoupons(orderCheckReport.couponMatches);
		this.setActiveLoyaltyDiscount(orderCheckReport.loyaltyMatch);
		this.businessStore.comoRewardsStore?.reinitialize();
	}

	public setPaymentMethod(paymentMethod: PaymentMethodDescriptor | undefined): void
	{
		this.paymentMethod = paymentMethod;
	}

	// ----------------------- Private logic ------------------------

	private async persistAndValidateChange(changeRecord: ChangeRecord): Promise<void>
	{
		const cartChange = changeRecord.cartChange;
		changeRecord.setSentToServer();
		const cartChangeResponse: CartChangeResponse = await post(
			'/client/business/cart/change',
			{
				cartChange: cartChange,
				checkRestrictions: this.doCheckRestrictionsOnCartChange,
				scheduledFor: this.orderScheduledFor,
			},
			CartChangeResponseProfile,
		);
		changeRecord.setDate(cartChangeResponse.dateChanged);
		this.truncateWriteAheadLog();
		if (this.doCheckRestrictionsOnCartChange)
			this.setRestrictionReports(cartChangeResponse.restrictions);

		if (!cartChangeResponse.success)
			throw new CartChangeFailedException();
	}

	private get feeAmountByFee(): Map<ProductFee, Decimal>
	{
		const result = new Map<ProductFee, Decimal>();

		this
			.writeAheadCartContents
			.forEach((quantity, configuration) =>
			{
				configuration.product?.fees?.forEach(fee =>
				{
					if (fee.applyOncePerOrder)
					{
						if (!result.has(fee))
							result.set(fee, computeFixedFee(fee, 1));

						result.set(
							fee,
							result.get(fee)!.add(
								computePercentageFeeForOrderLine(
									fee,
									this.getUnitPrice(configuration).sub(this.getUnitDiscount(configuration)),
									quantity,
									// fees that are applied once, do not require unit rounding.
									undefined,
								),
							),
						);
					}
					else
					{
						const totalAmount = computeFixedFee(fee, quantity)
							.add(
								computePercentageFeeForOrderLine(
									fee,
									this.getUnitPrice(configuration).sub(this.getUnitDiscount(configuration)),
									quantity,
									// fees that are applied multiple times, require unit rounding.
									this.currency.decimalPlaces,
								),
							);

						if (result.has(fee))
						{
							result.set(
								fee,
								result.get(fee)!.add(totalAmount),
							);
						}
						else
						{
							result.set(fee, totalAmount);
						}
					}
				});
			});

		// apply correct rounding for the product fees
		return Array.from(result.entries())
			.reduce(
				(result, [fee, value]) =>
					result.set(
						fee,
						value.toDecimalPlaces(
							this.currency.decimalPlaces,
							Decimal.ROUND_FLOOR,
						),
					),
				new Map<ProductFee, Decimal>(),
			);
	}

	setOrderProcessing(orderProcessing: boolean): void
	{
		this.orderProcessing = orderProcessing;
	}
}

function isCartLineDescribingProductConfiguration(
	cartLine: {
		productId: number,
		variants: {
			variantId: number,
			assignmentId: number,
		}[],
	},
	productConfiguration: ProductConfiguration,
): boolean
{
	return cartLine.productId === productConfiguration.product.id
		&& cartLine.variants.length === productConfiguration.variantConfigurations.length
		&& cartLine.variants.every(
			cartLineVariant => productConfiguration.variantConfigurations.find(
				variantConfiguration => cartLineVariant.assignmentId === variantConfiguration.assignment.id
					&& cartLineVariant.variantId === variantConfiguration.variant.id,
			) !== undefined,
		);
}

class ChangeRecord
{
	cartChange: CartChange;
	date: Date | undefined;
	sentToServer: boolean;

	constructor(cartChange: CartChange, date?: Date)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
		this.cartChange = cartChange;
		this.date = date;
		this.sentToServer = false;
	}

	public setDate(date: Date)
	{
		this.date = date;
	}

	public setSentToServer()
	{
		this.sentToServer = true;
	}
}

function computeFixedFee(fee: ProductFee, quantity: number): Decimal
{
	return new Decimal(fee.feeAmount).mul(quantity);
}

function computePercentageFeeForOrderLine(
	productFee: ProductFee,
	unitPrice: Decimal,
	quantity: number,
	decimalPlaces: number | undefined,
): Decimal
{
	let unitFeeAmount = unitPrice
		.mul(productFee.feePercentage);

	if (decimalPlaces !== undefined)
	{
		unitFeeAmount = unitFeeAmount
			.toDecimalPlaces(decimalPlaces, Decimal.ROUND_FLOOR);
	}

	return unitFeeAmount
		.mul(quantity);
}

function computeAdditiveTaxAmount(percentage: number, amount: Decimal)
{
	return amount
		.mul(percentage);
}

function isPlaceIncludedInSelection(
	place: Place,
	includedPlaceIds: Set<number> | undefined,
	excludedPlaceIds: Set<number> | undefined,
)
{
	if (includedPlaceIds !== undefined)
		return includedPlaceIds.has(place.id);
	if (excludedPlaceIds !== undefined)
		return !excludedPlaceIds.has(place.id);

	return true;
}

function isCategoryIncludedInSelection(
	category: ProductCategory,
	includedCategoryIds: Set<number> | undefined,
	excludedCategoryIds: Set<number> | undefined,
)
{
	const relevantCategories = category.getParentCategories();

	const isIncluded = includedCategoryIds === undefined ||
		relevantCategories
			.some(category => includedCategoryIds.has(category.id));

	if (isIncluded)
	{
		return excludedCategoryIds === undefined ||
			relevantCategories.every(category => !excludedCategoryIds.has(category.id));
	}
	else
	{
		return false;
	}
}

function isMenuCardIncludedInSelection(
	menuCardId: number | undefined,
	includedMenuCardIds: Set<number> | undefined,
	excludedMenuCardIds: Set<number> | undefined,
)
{
	const isIncluded = includedMenuCardIds === undefined ||
		(menuCardId !== undefined && includedMenuCardIds.has(menuCardId));

	if (isIncluded)
	{
		return excludedMenuCardIds === undefined ||
			menuCardId === undefined ||
			!excludedMenuCardIds.has(menuCardId);
	}
	else
	{
		return false;
	}
}