import { useObserver } from 'mobx-react-lite';
import { useMemo, useRef } from 'react';
import { useStorage } from '../Component/Root/StorageContextProvider';
import { StoredVariable } from './StoredVariable';

export function useStoredVariable<T>(
	keyInStorage: string,
	fromString: (stringValue: string | undefined) => T,
	valueToString: (value: T) => string | undefined,
): [T | undefined, (value: T) => Promise<void>]
{
	const storage = useStorage(true);

	const fromStringRef = useRef(fromString);
	fromStringRef.current = fromString;

	const valueToStringRef = useRef(valueToString);
	valueToStringRef.current = valueToString;

	const storedVariable = useMemo(
		() => {
			const storedVariable = new StoredVariable<T>(
				storage,
				keyInStorage,
				fromStringRef.current,
				valueToStringRef.current,
			);
			storedVariable.syncWithStorage();
			return storedVariable;
		},
		[keyInStorage, storage]
	);
	return [
		useObserver(() => storedVariable.initialized ? storedVariable.value : undefined),
		value => storedVariable.set(value)
	];
}