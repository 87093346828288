import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { getTopBarHeight } from '../../../../UI/PageTopBar';
import { SessionStatusBarPlaceholder } from '../../../../UI/session-status-bar/SessionStatusBarPlaceholder';
import { useNumberOfFullscreenMenuGridColumns } from '../../Menu/Grid/Api/useNumberOfFullscreenMenuGridColumns';
import { MENU_LIST_ITEM_MIN_HEIGHT, MENU_TILE_MIN_HEIGHT } from '../../Menu/Grid/MenuGrid';
import { Mixedgrid } from '../../Menu/Grid/MixedGrid/MixedGrid';
import { MixedGridItem } from '../../Menu/Grid/MixedGrid/MixedGridItem';
import { ComoPointShopBalance } from './ComoPointShopBalance';
import { ComoRewardsStore } from './ComoRewardsStore';
import { ComoRewardTile } from './ComoRewardTile';

const useStyle = makeStyles<Theme, { isKioskMode: boolean }>(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginRight: 0, // to make menu's top bar appear under sidebar's round edges
		overflow: 'hidden',
		paddingRight: 0, // to make menu's top bar appear under sidebar's round edges
	},
	menuGrid: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	menuGridListContainer: (({isKioskMode}) =>
	{
		const base: BaseCSSProperties = {
			display: 'flex',
			flex: '1 0 auto',
			flexDirection: 'column',
		};

		const paddingValue = theme.spacing(1);

		const additional: BaseCSSProperties = {
			paddingLeft: paddingValue,
			paddingRight: paddingValue,
			paddingBottom: paddingValue,

			[theme.breakpoints.down('sm')]: {
				marginTop:
					getTopBarHeight(
						true,
						isKioskMode,
					) + paddingValue,
			},
			[theme.breakpoints.up('md')]: {
				marginTop:
					getTopBarHeight(
						false,
						isKioskMode,
					) + paddingValue,
			},
		};

		return {...base, ...additional};
	}),
	grid: {
		flex: '1 0 auto !important',
		display: 'flex',
		flexDirection: 'column',
	},
	mixedGrid: {
		flex: '1 0 auto',
	},
}));

interface InitializedComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const InitializedComoRewardsContent: FC<InitializedComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const isKioskMode = useIsKioskMode();
		const classes = useStyle({isKioskMode});
		const numberOfGridColumns = useNumberOfFullscreenMenuGridColumns();
		const rewards = useObserver(() => store.rewards);

		const nrOfGridItems = rewards.length;

		const getMinImageWidth = useCallback((rowLength: number) => window.devicePixelRatio * window.innerWidth / rowLength, []);

		const rowHeight = useMemo(
			() => Math.max(
				MENU_TILE_MIN_HEIGHT,
				window.innerHeight / Math.ceil(nrOfGridItems / numberOfGridColumns),
			),
			[numberOfGridColumns, nrOfGridItems],
		);

		const minImageHeight = useMemo(() => window.devicePixelRatio * rowHeight, [rowHeight]);

		const gridItems = useMemo<MixedGridItem[]>(() =>
		{
			return rewards.map(reward => ({
				type: 'TILE',
				order: 0,
				key: `Reward-${reward.id}`,
				node: ({rowLength}) => <ComoRewardTile
					store={store}
					reward={reward}
					minImageHeight={minImageHeight}
					minImageWidth={getMinImageWidth(rowLength)}
				/>,
			}));
		}, [getMinImageWidth, minImageHeight, rewards, store]);

		return <div className={classes.container}>
			<div className={classes.menuGrid}>
				<div className={classes.menuGridListContainer}>
					<Grid
						classes={{
							container: classes.menuGrid,
						}}
						container
						spacing={1}
					>
						<ComoPointShopBalance store={store} />
						<Grid
							item
							xs={12}
							classes={{
								item: classes.grid,
							}}
						>
							<Mixedgrid
								className={classes.mixedGrid}
								nrOfColumns={numberOfGridColumns}
								items={gridItems}
								listItemHeight={MENU_LIST_ITEM_MIN_HEIGHT}
								minTileHeight={MENU_TILE_MIN_HEIGHT}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
			<SessionStatusBarPlaceholder />
		</div>;
	};
