import { useEffect } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { StorageVars } from '../../../../../../Constants/StorageConstants';
import { useStorage } from '../../../../../Root/StorageContextProvider';

export function useStoreSelectedPaymentMethod(selectedPaymentMethod: PaymentMethodDescriptor | undefined, didSelectInitialPaymentMethod: boolean): void
{
	const storage = useStorage(true);

	useEffect(() =>
	{
		if (didSelectInitialPaymentMethod)
		{
			storage
				.set(StorageVars.PaymentMethod, selectedPaymentMethod?.id)
				.then();
		}
	}, [didSelectInitialPaymentMethod, selectedPaymentMethod?.id, storage]);
}