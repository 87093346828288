import { ComponentClass } from 'react';
import { Bridge } from './Bridge/Bridge';
import { Screen } from './Bridge/Navigator/Screen';
import { AgeVerificationVoucherStore } from './Component/Page/AgeVerificationVoucher/AgeVerificationVoucherStore';
import { BillStore } from './Component/Page/Bill/BillStore';
import { AnnouncementScreenContext, AnnouncementScreenContextProvider, AnnouncementScreenContextRef } from './Component/Page/Business/Announcement/AnnouncementScreenContext';
import { AnnouncementStore } from './Component/Page/Business/Announcement/AnnouncementStore';
import { BusinessContext, BusinessContextProvider, BusinessContextRef } from './Component/Page/Business/BusinessContext';
import { BusinessHooksProvider } from './Component/Page/Business/BusinessHooksProvider';
import { BusinessStore } from './Component/Page/Business/BusinessStore';
import { ComoRewardStore } from './Component/Page/Business/Como/Reward/ComoRewardStore';
import { ComoRewardsStore } from './Component/Page/Business/Como/Rewards/ComoRewardsStore';
import { HistoryStore } from './Component/Page/Business/History/HistoryStore';
import { MenuStore } from './Component/Page/Business/Menu/MenuStore';
import { OrderBuilderStore } from './Component/Page/Business/OrderBuilder/OrderBuilderStore';
import { ProductRecommendationStore } from './Component/Page/Business/Product/ProductRecommendation/ProductRecommendationStore';
import { ProductStore } from './Component/Page/Business/Product/ProductStore';
import { EntranceContext, EntranceContextProvider, EntranceContextRef } from './Component/Page/Entrance/EntranceContext';
import { EntranceStore } from './Component/Page/Entrance/EntranceStore';
import { LoginStore } from './Component/Page/Login/LoginStore';
import { ManagerStore } from './Component/Page/Manager/ManagerStore';
import { ProfileStore } from './Component/Page/Profile/ProfileStore';
import { ScannerStore } from './Component/Page/Scanner/ScannerStore';
import { Screens } from './Constants/ScreenConstants';
import { AuthenticationService } from './Service/Authentication/AuthenticationService';
import { BrandingService } from './Service/BrandingInformation/BrandingService';
import { CurrentPlaceService } from './Service/CurrentPlace/CurrentPlaceService';
import { ScaninService } from './Service/EnteringService/ScaninService';
import { KioskService } from './Service/KioskService/KioskService';
import { LocationService } from './Service/Location/LocationService';
import { ClockService } from './Util/clock/ClockService';
import { findQueryParameter } from './Util/findQueryParameter';

export function getRootScreen(
	bridge: Bridge,
	authenticationService: AuthenticationService,
	clockService: ClockService,
	locationService: LocationService,
	kioskService: KioskService,
	currentPlaceService: CurrentPlaceService,
	brandingService: BrandingService,
	scaninService: ScaninService,
	screenById: Map<string, ComponentClass>,
): Screen<any, any>
{
	const loginScreen = new Screen<LoginStore>(
		Screens.Login,
		() => screenById.get(Screens.Login)!,
		'login',
		(route, props, parentStore: EntranceStore) =>
			parentStore.attemptEnterManager('order-handler') as Promise<LoginStore>,
		undefined,
		undefined,
		undefined,
		() => 'login',
	);

	const managerScreen = new Screen<ManagerStore>(
		Screens.Manager,
		() => screenById.get(Screens.Manager)!,
		'manager/:view',
		(route, props, parentStore: EntranceStore) =>
			parentStore.attemptEnterManager(props.view || 'order-handler') as Promise<ManagerStore>,
		undefined,
		undefined,
		undefined,
		store => `manager/${store.view}`,
	);

	const productScreen = new Screen<ProductStore>(
		Screens.Product,
		() => screenById.get(Screens.Product)!,
		'product/:productId',
		(route, props, parentStore: BusinessStore | MenuStore) =>
		{
			if (parentStore.productById)
			{
				const product = parentStore.productById.get(parseInt(props.productId, 10));

				if (product)
				{
					return parentStore.openProduct(product);
				}
				else
				{
					return Promise.resolve(null);
				}
			}
			else
			{
				return Promise.resolve(null);
			}
		},
		undefined,
		undefined,
		undefined,
		store => `product/${store.product.id}/`,
		[],
		undefined,
		undefined,
		true,
	);

	const announcementScreen = new Screen<AnnouncementStore, AnnouncementScreenContext>(
		Screens.Announcement,
		() => screenById.get(Screens.Announcement)!,
		'announcement/:announcementId',
		async (route, props, parentStore: BusinessStore | MenuStore) =>
		{
			const announcementId = parseInt(props.announcementId);
			const announcement = parentStore.announcements
				.find(({id}) => id === announcementId);
			return announcement !== undefined
				? new AnnouncementStore(announcement)
				: null;
		},
		AnnouncementScreenContextRef,
		AnnouncementScreenContextProvider,
		(parentContext, {announcement}) => ({
			announcement,
		}),
		store => `announcement/${store.announcement.id}/`,
		[],
		undefined,
		undefined,
		true,
	);

	const productRecommendationScreen = new Screen<ProductRecommendationStore>(
		Screens.ProductRecommendations,
		() => screenById.get(Screens.ProductRecommendations)!,
		'recommendations/:productId',
		(route, props, parentStore: BusinessStore | MenuStore) =>
		{
			const parsedProductId = parseInt(props.productId, 10);
			return parentStore.openProductRecommendations(parsedProductId);
		},
		undefined,
		undefined,
		undefined,
		store => `recommendations/${store.triggeringProductId}/`,
		[
			productScreen,
		],
		undefined,
		undefined,
		true,
	);

	const orderBuilderScreen = new Screen<OrderBuilderStore>(
		Screens.OrderBuilder,
		() => screenById.get(Screens.OrderBuilder)!,
		'order',
		(route, props, parentStore: BusinessStore | MenuStore | ComoRewardsStore) =>
			parentStore.openOrderBuilder()
		,
		undefined,
		undefined,
		undefined,
		() => 'order/',
		[
			productScreen,
		],
		undefined,
		undefined,
		true,
	);

	const historyScreen = new Screen<HistoryStore>(
		Screens.History,
		() => screenById.get(Screens.History)!,
		'history',
		(route, props, parentStore: BusinessStore | MenuStore | EntranceStore) =>
			parentStore.openHistory(true),
		undefined,
		undefined,
		undefined,
		() => `history/`,
		[
			new Screen<ProductStore>(
				Screens.Product,
				() => screenById.get(Screens.Product)!,
				'product/:productId',
				(route, props, parentStore: HistoryStore) =>
				{
					const product = parentStore.productById.get(parseInt(props.productId, 10));
					return parentStore.openProduct === undefined
					|| product === undefined
						?
						Promise.reject()
						:
						Promise.resolve(parentStore.openProduct(product));
				},
				undefined,
				undefined,
				undefined,
				store => `product/${store.product.id}/`,
				[],
				undefined,
				undefined,
				true,
			),
		],
		undefined,
		undefined,
		true,
	);

	const comoRewardScreen = new Screen<ComoRewardStore>(
		Screens.ComoReward,
		() => screenById.get(Screens.ComoReward)!,
		':rewardId',
		(_route, props, parentStore: ComoRewardsStore) =>
		{
			let reward = parentStore.rewardsById.get(props.rewardId);

			if (reward)
			{
				return parentStore.openReward(reward);
			}
			else
			{
				return Promise.resolve(null);
			}
		},
		undefined,
		undefined,
		undefined,
		(store) => `${store.reward.id}/`,
		[],
		undefined,
		undefined,
		true,
	);

	const comoRewardsScreen = new Screen<ComoRewardsStore>(
		Screens.ComoRewards,
		() => screenById.get(Screens.ComoRewards)!,
		'loyalty/rewards/',
		(_route, _props, parentStore: BusinessStore | MenuStore) =>
		{
			const businessStore = (parentStore instanceof BusinessStore) ? parentStore : parentStore.businessStore;

			if (businessStore.loyaltyIntegration === 'COMO')
			{
				return businessStore.openComoRewardsPage();
			}
			else
			{
				return Promise.resolve(null);
			}
		},
		undefined,
		undefined,
		undefined,
		() => 'loyalty/rewards/',
		[
			comoRewardScreen,
			orderBuilderScreen,
			historyScreen,
			productScreen,
		],
		undefined,
		undefined,
		false,
		true,
		undefined,
		undefined,
	);

	const menuScreen = new Screen<MenuStore>(
		Screens.Menu,
		() => screenById.get(Screens.Menu)!,
		'category/:categoryId',
		(route, props, parentStore: BusinessStore | MenuStore) =>
		{
			if (parentStore.productCategoryById)
			{
				const category = parentStore.productCategoryById.get(parseInt(props.categoryId, 10));

				if (category)
				{
					return parentStore.openChildCategory(category);
				}
				else
				{
					return Promise.resolve(null);
				}
			}
			else
			{
				return Promise.resolve(null);
			}
		},
		undefined,
		undefined,
		undefined,
		store => store !== undefined ? `category/${store.category.id}/` : ``,
		[
			productScreen,
			productRecommendationScreen,
			orderBuilderScreen,
			historyScreen,
			announcementScreen,
			comoRewardsScreen,
		],
		undefined,
		undefined,
		false,
		true,
		undefined,
		(childScreen, store: MenuStore) =>
		{
			return !(store.entranceStore.hideOrderHistory && childScreen.id === Screens.History);
		},
	);

	menuScreen.childScreens.push(menuScreen);

	const profileScreen = new Screen<ProfileStore>(
		Screens.Profile,
		() => screenById.get(Screens.Profile)!,
		'profile',
		(route, props, parentStore: BusinessStore | EntranceStore) =>
			parentStore.openProfile(),
		undefined,
		undefined,
		undefined,
		() => `profile/`,
		[],
		undefined,
		undefined,
		true,
	);

	const businessScreen = new Screen<BusinessStore, BusinessContext>(
		Screens.Business,
		() => screenById.get(Screens.Business)!,
		':command(place|place-locked)/:hash',
		(route, props, parentStore: EntranceStore | ManagerStore, parentContext) =>
		{
			const entranceStore: EntranceStore = parentStore instanceof EntranceStore
				? parentStore
				: parentStore.entranceStore;
			return entranceStore
				.enterBusinessFromHash(
					props.hash,
					props.command === 'place-locked'
				)
				.catch(() =>
				{
					if (route.endsWith('/history') || route.endsWith('/history/'))
						return entranceStore.openHistory();
					else
						return Promise.resolve(null);
				});
		},
		BusinessContextRef,
		BusinessContextProvider,
		(parentContext: EntranceContext, store) =>
		{
			return {
				currentPlaceService: store.currentPlaceService,
				currentOrderService: store.currentOrderService,
				currentAgeVerificationService: store.currentAgeVerificationService,
				clockService: parentContext.clockService,
				localizer: parentContext.localizer,
				businessStore: store,
			};
		},
		store =>
			`${store.entranceStore.hashOfLockedPlace ? 'place-locked' : 'place'}/-/${store.openedChildCategories.map(category => category.id).join('-')}${store.openedChildCategories.length > 0 ? '/' : ''}`,
		// `place/${store.placeHash}/${store.openedChildCategories.map(category => category.id).join('-')}${store.openedChildCategories.length > 0 ? '/' : ''}`,
		[
			loginScreen,
			managerScreen,
			menuScreen,
			productScreen,
			productRecommendationScreen,
			orderBuilderScreen,
			profileScreen,
			historyScreen,
			announcementScreen,
			comoRewardsScreen,
		],
		new Screen(
			Screens.BusinessNavigationMenu,
			() => screenById.get(Screens.BusinessNavigationMenu)!,
		),
		new Screen(
			Screens.BusinessOrderBuilderSidebar,
			() => screenById.get(Screens.BusinessOrderBuilderSidebar)!,
		),
		// undefined,
		false,
		false,
		true,
		(childScreen, store: BusinessStore) =>
		{
			if (store.shouldShowNoOpenPlaceSessionScreen)
			{
				return false;
			}

			if (store.shouldShowLockedForPaymentTerminalScreen)
			{
				return false;
			}

			if (store.entranceStore.hideOrderHistory
				&& childScreen.id === Screens.History)
			{
				return false;
			}

			return !(store.entranceStore.userProfileHidden.value && childScreen.id === Screens.Profile);
		},
		BusinessHooksProvider,
	);

	managerScreen.childScreens.push(businessScreen);

	const scannerScreen = new Screen<ScannerStore<BusinessStore>>(
		Screens.Scanner,
		() => screenById.get(Screens.Scanner),
		'scan',
		(route, props, parentStore: EntranceStore) =>
			parentStore.initialize()
				.then(parentStore.startScanning)
				.then(screenInstance => screenInstance.store as ScannerStore<BusinessStore>),
		undefined,
		undefined,
		undefined,
		() => 'scan',
		undefined,
		undefined,
		undefined,
		true,
	);

	const businessId = findQueryParameter('businessId');
	const placeName = findQueryParameter('placeName');
	const externalId = findQueryParameter('externalId');

	const billScreen = new Screen<BillStore>(
		Screens.Bill,
		() => screenById.get(Screens.Bill),
		'bill',
		async (route, props, parentStore: EntranceStore) =>
		{
			const billStore =
				new BillStore(
					bridge,
					{
						businessId,
						placeName,
						externalId,
					},
				);

			await parentStore.push(
				Screens.Bill,
				billStore,
			);

			return billStore;
		},
		undefined,
		undefined,
		undefined,
		store =>
			`bill?businessId=${encodeURIComponent(store.request.businessId)}&placeName=${encodeURIComponent(store.request.placeName)}&externalId=${encodeURIComponent(store.request.externalId)}`,
		undefined,
		undefined,
		undefined,
		true,
	);

	const token = findQueryParameter('token');

	const ageVerificationVoucherScreen = new Screen<AgeVerificationVoucherStore>(
		Screens.AgeVerificationVoucher,
		() => screenById.get(Screens.AgeVerificationVoucher),
		'ageVerificationVoucher',
		async (route, props, parentStore: EntranceStore) =>
		{
			const ageVerificationVoucherStore = new AgeVerificationVoucherStore(token);

			await parentStore.push(
				Screens.AgeVerificationVoucher,
				ageVerificationVoucherStore,
			);

			return ageVerificationVoucherStore;
		},
		undefined,
		undefined,
		undefined,
		store =>
			`ageVerificationVoucher?token=${encodeURIComponent(store.token)}`,
		undefined,
		undefined,
		undefined,
		true,
	);

	return new Screen<EntranceStore, EntranceContext>(
		Screens.Entrance,
		() => screenById.get(Screens.Entrance)!,
		'/',
		() => Promise.resolve(
			new EntranceStore(
				bridge,
				authenticationService,
				clockService,
				locationService,
				kioskService,
				currentPlaceService,
				brandingService,
				scaninService,
			),
		),
		EntranceContextRef,
		EntranceContextProvider,
		(parentContext, store) =>
		{
			return {
				authenticationService: store.authenticationService,
				brandingService: brandingService,
				client: bridge.client,
				clockService: store.clockService,
				linking: bridge.linking,
				localizer: bridge.localizer,
				notification: bridge.notification,
				openPlace: store.openPlace,
				orderService: store.orderService,
				profileService: store.profileService,
				entranceStore: store,
				storage: bridge.storage,
				waiterService: store.waiterService,
			};
		},
		() => '/',
		[
			loginScreen,
			managerScreen,
			scannerScreen,
			businessScreen,
			profileScreen,
			historyScreen,
			billScreen,
			ageVerificationVoucherScreen,
		],
		new Screen(
			Screens.EntranceNavigationMenu,
			() => screenById.get(Screens.EntranceNavigationMenu)!,
		),
		undefined,
		undefined,
		undefined,
		undefined,
		(childScreen: Screen, store: EntranceStore) =>
		{
			if (store.hashOfLockedPlace)
			{
				return childScreen.id === Screens.Business
					|| childScreen.id === Screens.Manager;
			}
			else
			{
				return true;
			}
		},
	);
}
