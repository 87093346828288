import { Loader } from '@intentic/ts-foundation';
import { isWidthDown, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { StorageVars } from '../../../../../Constants/StorageConstants';
import { UIColors } from '../../../../../Constants/UIColors';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { hasOpenPayment } from '../../../../../Util/Orders/hasOpenPayment';
import { isPaid } from '../../../../../Util/Orders/isPaid';
import { useStorage } from '../../../../Root/StorageContextProvider';
import { CollapsibleBottomBar } from '../../../../UI/CollapsibleBottomBar';
import { useManagerContext } from '../../manager-context';
import {
	useDeliveredOrders,
	useInPreparationOrders,
	useOrderedOrders,
	usePaidCompleteOrders,
	usePaidVoidedOrders,
	usePickedUpOrders,
	usePreparedOrders,
	useScheduledOrders,
	useUnpaidCompleteOrders,
	useUnpaidVoidedOrders,
} from '../context/order/order-handler-order-context';
import { OrderListOrder } from '../context/order/OrderListOrder';
import { OrderList } from '../order-list/OrderList';
import { OrderContainerMultiple } from './multiple/OrderContainerMultiple';
import { NamedOrderList } from './NamedOrderList';
import { OrderContainerSingle } from './single/OrderContainerSingle';

const useStyles = makeStyles(() => ({
	paidOrderList: {
		backgroundColor: UIColors.positive,
	},
}));

interface OrderContainerProps
{
}

export const OrderContainer: FC<OrderContainerProps> =
	() =>
	{
		const classes = useStyles();

		const storage = useStorage(true);

		const {
			waiterService,
		} = useManagerContext();

		const [showPaidOrders, setShowPaidOrders] = useState<boolean>(true);
		const screenWidth = useScreenWidth();

		const finalOrderHandlingState = useMemo(
			() =>
			{
				if (waiterService.user?.orderHandlerSupportsOrderStateDelivered) return 'delivered';
				else if (waiterService.user?.orderHandlerSupportsOrderStatePickedUp) return 'pickedUp';
				else if (waiterService.user?.orderHandlerSupportsOrderStatePrepared) return 'prepared';
				else return 'handled';
			},
			[waiterService.user]
		)
		
		useEffect(
			() => {
				storage.get(StorageVars.ManagerShowPaidHandledOrders)
					.then(value => {
						if (value)
						{
							setShowPaidOrders(value === 'true');
						}
						else
						{
							storage.set(StorageVars.ManagerShowPaidHandledOrders, 'true').then();
							setShowPaidOrders(true);
						}
					});
			},
			[storage]
		);

		const translate = useTranslate();

		const scheduledOrders = useScheduledOrders();
		const scheduledList = useMemo(
			() => renderOrderList(scheduledOrders, translate('Order-State-Scheduled'), 'scheduled-order-list'),
			[scheduledOrders, translate],
		);

		const orderedOrders = useOrderedOrders();
		const orderedList = useMemo(
			() => renderOrderList(orderedOrders, translate('Order-State-Ordered'), 'ordered-order-list'),
			[orderedOrders, translate],
		);

		const unpaidCompleteOrders = useUnpaidCompleteOrders();
		const preparedOrders = usePreparedOrders();
		const preparedList = useMemo(
			() => finalOrderHandlingState === 'prepared'
				? renderOrderList(unpaidCompleteOrders?.reverse(), `${translate('Order-State-Prepared')} / ${translate('Order-State-Handled')}`, 'prepared-list-root')
				: renderOrderList(preparedOrders, translate('Order-State-Prepared'), 'prepared-list-root'),
			[finalOrderHandlingState, preparedOrders, translate, unpaidCompleteOrders],
		);

		const inPreparationOrders = useInPreparationOrders();
		const inPreparationList = useMemo(
			() => finalOrderHandlingState === 'handled'
				? renderOrderList(unpaidCompleteOrders?.reverse(), `${translate('Order-State-Acknowledged')} / ${translate('Order-State-Handled')}`, 'acknowledged-order-list')
				: renderOrderList(inPreparationOrders, translate('Order-State-Acknowledged'), 'acknowledged-order-list'),
			[finalOrderHandlingState, inPreparationOrders, translate, unpaidCompleteOrders],
		);

		const pickedUpOrders = usePickedUpOrders();
		const pickedUpList = useMemo(
			() => finalOrderHandlingState === 'pickedUp'
				? renderOrderList(unpaidCompleteOrders?.reverse(), `${translate('Order-State-PickedUp')} / ${translate('Order-State-Handled')}`, 'picked-up-order-list')
				: renderOrderList(pickedUpOrders, translate('Order-State-PickedUp'), 'picked-up-order-list'),
			[finalOrderHandlingState, pickedUpOrders, translate, unpaidCompleteOrders],
		);

		const deliveredOrders = useDeliveredOrders();
		const deliveredList = useMemo(
			() => finalOrderHandlingState === 'delivered'
				? renderOrderList(unpaidCompleteOrders?.reverse(), `${translate('Order-State-Delivered')} / ${translate('Order-State-Handled')}`, 'delivered-order-list')
				: renderOrderList(deliveredOrders, translate('Order-State-Delivered'), 'delivered-order-list'),
			[deliveredOrders, finalOrderHandlingState, translate, unpaidCompleteOrders],
		);

		const unpaidVoidedOrders = useUnpaidVoidedOrders();
		const unpaidVoidedList = useMemo(
			() => renderOrderList(
				unpaidVoidedOrders
					?.filter(({order}) => !isPaid(order))
					.filter(({order}) => hasOpenPayment(order))
					.reverse(),
				translate('Order-State-Voided'),
				'voided-order-list'
			),
			[translate, unpaidVoidedOrders],
		)

		const paidCompleteOrders = usePaidCompleteOrders();
		const paidCompleteOrderList = useMemo(
			() =>
				paidCompleteOrders !== undefined
					? <OrderList
						className={paidCompleteOrders.length > 0 ? classes.paidOrderList : ''}
						id="handled-paid-order-list"
						key="handled-paid"
						orders={paidCompleteOrders.reverse()}
						title={translate('Generic-Paid')}
					/>
					: undefined,
			[classes.paidOrderList, paidCompleteOrders, translate]
		);

		const paidVoidedOrders = usePaidVoidedOrders();
		const paidVoidedOrderList = useMemo(
			() =>
			{
				return paidVoidedOrders !== undefined
					? <OrderList
						className={paidVoidedOrders.length > 0 ? classes.paidOrderList : ''}
						id="voided-paid-order-list"
						key="voided-paid"
						orders={paidVoidedOrders.reverse()}
						title={translate('Generic-Paid')}
					/>
					: undefined
			},
			[classes.paidOrderList, paidVoidedOrders, translate]
		);
		
		const paidSuccessfulOrdersBottomBar = useMemo(
			() =>
				showPaidOrders &&
				<CollapsibleBottomBar
					details={paidCompleteOrderList}
					id="complete-paid-order-button"
					summary={<Typography>{translate('Generic-Paid')}</Typography>}
				/>,
			[paidCompleteOrderList, showPaidOrders, translate],
		);

		const paidVoidedOrdersBottomBar = useMemo(
			() =>
				showPaidOrders &&
				<CollapsibleBottomBar
					details={paidVoidedOrderList}
					id="voided-paid-order-button"
					summary={<Typography>{translate('Generic-Paid')}</Typography>}
				/>,
			[paidVoidedOrderList, showPaidOrders, translate],
		);

		const activeLists: NamedOrderList[] | undefined = useMemo(
			() =>
			{
				if (orderedList === undefined || inPreparationList === undefined)
					return undefined;

				const lists = [
					scheduledList,
					orderedList,
					inPreparationList,
					preparedList,
					pickedUpList,
					deliveredList,
					unpaidVoidedList,
				]
					.filter(orderList => orderList !== undefined)
					.map(orderList => orderList!);

				const lastListIdx = lists.length - 1;
				const secondToLastListIdx = lists.length - 2;

				lists[lastListIdx] = {
					name: lists[lastListIdx].name,
					list: <>
						{lists[lastListIdx].list}
						{paidVoidedOrdersBottomBar}
					</>,
				};

				lists[secondToLastListIdx] = {
					name: lists[secondToLastListIdx].name,
					list: <>
						{lists[secondToLastListIdx].list}
						{paidSuccessfulOrdersBottomBar}
					</>,
				};

				return lists;
			},
			[deliveredList, inPreparationList, orderedList, paidSuccessfulOrdersBottomBar, paidVoidedOrdersBottomBar, pickedUpList, preparedList, scheduledList, unpaidVoidedList],
		);

		return useMemo(
			() => activeLists !== undefined
				?
				isWidthDown('sm', screenWidth)
					? <OrderContainerSingle orderLists={activeLists} />
					: <OrderContainerMultiple orderLists={activeLists} />
				:
				<Loader/>,
			[activeLists, screenWidth],
		);
	};

function renderOrderList(
	orders: OrderListOrder[] | undefined = undefined,
	name: string,
	id: string
)
{
	return orders !== undefined
		? {
			name,
			list: <OrderList
				id={id}
				orders={orders}
				title={name}
			/>,
		}
		: undefined;
}
