import * as React from 'react';
import { Place } from '../../../Api/Business/Place';
import { Client } from '../../../Bridge/Client/Client';
import { Linking } from '../../../Bridge/Linking/Linking';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { Notification } from '../../../Bridge/Notification/Notification';
import { Storage } from '../../../Bridge/Storage/Storage';
import { AuthenticationService } from '../../../Service/Authentication/AuthenticationService';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { OrderService } from '../../../Service/OrderService/OrderService';
import { ProfileService } from '../../../Service/ProfileService';
import { WaiterService } from '../../../Service/UserService/WaiterService';
import { ClockService } from '../../../Util/clock/ClockService';
import { EntranceStore } from './EntranceStore';

export interface EntranceContext
{
    authenticationService: AuthenticationService
    brandingService: BrandingService
    entranceStore: EntranceStore
    client: Client
    clockService: ClockService
    linking: Linking
    localizer: Localizer
    notification: Notification
    openPlace: (place: Place, locked: boolean, fromRoot: boolean) => Promise<void>
    orderService: OrderService
    profileService: ProfileService
    storage: Storage
    waiterService: WaiterService
}

export const EntranceContextRef = React
    .createContext<EntranceContext>({} as EntranceContext);

export const EntranceContextProvider = EntranceContextRef.Provider;
export const EntranceContextConsumer = EntranceContextRef.Consumer;
