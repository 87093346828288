import { action, makeObservable, observable } from 'mobx';
import * as SockJS from 'sockjs-client';
import { Exception } from '../../../Util/Exception/Exception';

export type ReconnectingWebSocketStatus = 'CONNECTED' | 'DISCONNECTED';

/**
 * Reconnect interval in milliseconds
 */
const RECONNECT_INTERVAL = 5000;

export type ReconnectingWebSocketOptions = {
	logMessages?: boolean;
}

export class ReconnectingWebSocket
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly apiEndpoint: string;
	private readonly onOpen: (thiss: ReconnectingWebSocket) => void = async () => {};
	private readonly onMessage: (thiss: ReconnectingWebSocket, messageEvent: MessageEvent) => void = () => {};
	private readonly onClose: (thiss: ReconnectingWebSocket) => void = () => {};
	private readonly _options: ReconnectingWebSocketOptions;
	private webSocket: WebSocket | undefined;

	/**
	 * The status of the GraphQL websocket connection. Note that this status should be used only for purposes of warning clients. Clients
	 * can still send messages down this {@link GraphqlWebSocketService}, as it is expected to reconnect and until it does, the messages
	 * are queued.
	 */
	public currentStatus: ReconnectingWebSocketStatus;

	private allowedToReopen: boolean;

	private reconnectInterval!: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	/**
	 * @param apiEndpoint
	 * @param onOpen A callback for when the underlying WebSocket connects. May happen multiple times during
	 * the lifecycle of one {@link ReconnectingWebSocket}.
	 * @param onMessage
	 * @param onClose A callback for when the underlying WebSocket disconnects. Though this event fires,
	 * this {@link ReconnectingWebSocket} will keep reconnecting, so this callback may be called multiple
	 * times during the lifecyccle of one {@link ReconnectingWebSocket}.
	 * @param options
	 */
	constructor(
		apiEndpoint: string,
		onOpen: (thiss: ReconnectingWebSocket) => void = async () => {},
		onMessage: (thiss: ReconnectingWebSocket, messageEvent: MessageEvent) => void = () => {},
		onClose: (thiss: ReconnectingWebSocket) => void = () => {},
		options: ReconnectingWebSocketOptions = {},
	)
	{
		makeObservable<ReconnectingWebSocket, 'setCurrentStatus'>(
			this,
			{
				currentStatus: observable,
				setCurrentStatus: action.bound,
			},
		);

		this.apiEndpoint = apiEndpoint;
		this.onOpen = onOpen;
		this.onMessage = onMessage;
		this.onClose = onClose;
		this._options = options;
		this.setCurrentStatus('DISCONNECTED');
		this.webSocket = this.newWebSocket();
		this.allowedToReopen = true;
		this.resetReconnectInterval();
	}

	public send(messageJson: any)
	{
		if (this.webSocket === undefined)
		{
			throw new Exception('Message cannot be sent: WebSocket currently disconnected');
		}

		this.webSocket.send(JSON.stringify(messageJson));
		if (this.options.logMessages)
		{
			console.debug(`Message sent: ${JSON.stringify(messageJson)}`);
		}
	}

	public open(): void
	{
		this.allowedToReopen = true;
		this.connect();
	}

	public close(): void
	{
		this.allowedToReopen = false;
		if (this.webSocket !== undefined)
		{
			this.webSocket.close();
			this.webSocket = undefined;
		}
	}

	private reconnect(): void
	{
		if (this.allowedToReopen) {
			setTimeout(
				() => {
					this.connect();
					this.reconnectInterval += 1000;
				},
				this.reconnectInterval < RECONNECT_INTERVAL
					?
					this.reconnectInterval
					:
					RECONNECT_INTERVAL
			)
		}
	}

	private connect(): void
	{
		this.webSocket = this.newWebSocket();
	}

	private newWebSocket(): WebSocket
	{
		const webSocket = new SockJS(this.apiEndpoint);
		webSocket.onopen = async () => {
			this.setCurrentStatus('CONNECTED');
			this.resetReconnectInterval();
			this.onOpen(this);
		};
		webSocket.onmessage = messageEvent => {
			this.onMessage(this, messageEvent);
			if (this.options.logMessages)
			{
				console.debug(`Message received: ${messageEvent.data}`);
			}
		};
		webSocket.onclose = (closeEvent) => {
			this.setCurrentStatus('DISCONNECTED');
			this.onClose(this);
			this.reconnect();
		};
		return webSocket;
	}

	private get options(): ReconnectingWebSocketOptions
	{
		const specified = this._options;
		return {
			logMessages: specified.logMessages ?? false,
		}
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	private resetReconnectInterval()
	{
		this.reconnectInterval = 100;
	}

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	private setCurrentStatus(currentStatus: ReconnectingWebSocketStatus): void
	{
		this.currentStatus = currentStatus;
	}

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}
