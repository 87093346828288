import { Button, makeStyles, Typography } from '@material-ui/core';
import { ArrowForward, ShoppingBasket } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { MaxQuantityInOrderRestrictionMetadata } from '../../../../../Api/Order/OrderRestriction/MaxQuantityInOrderRestrictionMetadata';
import { OrderThroughputOrderRestrictionMetadata } from '../../../../../Api/Order/OrderRestriction/OrderThroughputOrderRestrictionMetadata';
import { useResizeObserver } from '../../../../../Util/Hooks/useResizeObserver';
import { CenteredPageContent } from '../../../../UI/CenteredPageContent';
import { useBottomBarFontSize, useBottomBarHeight } from '../../../../UI/session-status-bar/BottomBarConstants';
import { useAndRequireSessionStatusBarStore } from '../../../../UI/session-status-bar/SessionStatusBarStoreProvider';

const useStyles = makeStyles({
    ButtonLabel: {
        display: 'flex',
        gap: 20,
    }
});

export const CollapsedShoppingCart: FC =
    () =>
    {
        const store = useAndRequireSessionStatusBarStore();
        const [elementRef, element] = useResizeObserver();
        const classes = useStyles();
        const bottomBarHeight = useBottomBarHeight();
        const screenNarrow = useObserver(() => (element?.contentRect.width ?? store.viewportWidth) < 400);
        const fontSize = useBottomBarFontSize(screenNarrow);

        return useObserver(() => {
            const screenExtraNarrow = (element?.contentRect.width ?? store.viewportWidth) < 230;
            const localizer = store.bridge.localizer;
            const nrOfProductsInCart = store.shoppingCartStore.quantity;

            let nrOfAllowedProducts = undefined;
            let nrOfRelevantProducts = 0;
            const throughputReport = store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('Throughput');
            if (throughputReport === undefined)
            {
                const maxQuantityInOrderReport = store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('MaxQuantityInOrder')

                if (maxQuantityInOrderReport !== undefined)
                {
                    const maxQuantityInOrderMetadata = maxQuantityInOrderReport.metadata as MaxQuantityInOrderRestrictionMetadata;

                    nrOfAllowedProducts = Math.max(0, maxQuantityInOrderMetadata.quantityAllowed)
                    nrOfRelevantProducts = store.shoppingCartStore.currentOrderService.getRelevantQuantity(maxQuantityInOrderReport.configuration);
                }
            }
            else
            {
                const throughputMetadata = throughputReport.metadata as OrderThroughputOrderRestrictionMetadata;
                nrOfAllowedProducts = Math.max(0, throughputMetadata.quantityAllowed - throughputMetadata.quantityOrdered);
                nrOfRelevantProducts = store.shoppingCartStore.currentOrderService.getRelevantQuantity(throughputReport.configuration);
            }

            return <Button
                ref={elementRef}
                style={{
                    height: bottomBarHeight,
                    flex: '1 0 50%',
                    cursor: 'pointer',
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginLeft: -1,
                    textTransform: 'none',
                    textAlign: 'left',
                }}
                color="inherit"
                id="collapsed-shopping-cart-button"
                onClick={store.onOpenShoppingCart}
                classes={{
                    label: classes.ButtonLabel,
                }}
            >
                <CenteredPageContent>
                    <div
                        style={{
                            flex: '1 0 50%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left',
                            cursor: 'pointer',
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 15,
                            paddingRight: 15,
                            marginLeft: -1,
                            textTransform: 'none',
                            textAlign: 'left',
                            gap: 20,
                        }}
                    >
                        <ShoppingBasket
                            color="inherit"
                            style={{
                                flex: '0 0 auto'
                            }}
                            id="shopping-basket-icon"
                            fontSize={screenNarrow ? 'medium' : 'large'}
                        />
                        <div
                            style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                gap: '0 10px',
                            }}
                        >
                            <div
                                style={{
                                    flex: '1 1 auto',
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        fontSize,
                                    }}
                                >
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {
                                            nrOfProductsInCart === 1
                                                ?
                                                localizer.translate('Client-Order-Item', nrOfProductsInCart.toString())
                                                :
                                                localizer.translate('Client-Order-Items', nrOfProductsInCart.toString())
                                        }
                                    </span>
                                    {
                                        !screenExtraNarrow &&
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {
                                                ` ${localizer.translate('Client-Order-InOrder')}`
                                            }
                                        </span>
                                    }
                                    {
                                        !screenExtraNarrow &&
                                        nrOfRelevantProducts > 0 &&
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {` (${nrOfRelevantProducts}/${nrOfAllowedProducts})`}
                                        </span>
                                    }
                                </Typography>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color={!store.shoppingCartStore.isValueSufficient && store.shoppingCartStore.showValidation ? 'error' : 'inherit'}
                                    style={{
                                        fontSize,
                                    }}
                                >
                                    {
                                        store.bridge.localizer.formatCurrency(
                                            store.shoppingCartStore.basePrice,
                                            store.productCurrencyCode)
                                    }
                                </Typography>
                                {
                                    !store.shoppingCartStore.isValueSufficient &&
                                    <Typography
                                        variant="subtitle1"
                                        color="inherit"
                                        style={{
                                            fontSize,
                                        }}
                                    >
                                        &nbsp;/&nbsp;
                                        {
                                            store.bridge.localizer.formatCurrency(
                                                store.shoppingCartStore.currentOrderService.minimumOrderValue,
                                                store.productCurrencyCode,
                                            )
                                        }
                                    </Typography>
                                }
                            </div>
                        </div>
                        <ArrowForward
                            style={{
                                flex: '0 0 auto',
                            }}
                            fontSize="large"
                        />
                    </div>
                </CenteredPageContent>
            </Button>;
        });
    };
