import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Theme } from '@material-ui/core';
import { BackdropProps } from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/es/Typography';
import Grow from '@material-ui/core/Grow/Grow';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { ArrowBack, Close } from '@material-ui/icons';
import { Property } from 'csstype';
import * as React from 'react';
import { FC, ReactNode, useMemo, useRef } from 'react';
import { useScreenWidth } from '../../Util/Hooks/useScreenWidth';
import { panelGradient } from '../Page/Business/BusinessConstants';
import { CenteredPageContent } from './CenteredPageContent';
import { topBarHeight, topBarHeightMobile } from './PageTopBar';
import { BottomScrollIndicator } from './ScrollIndicator/BottomScrollIndicator';

interface StyleProps
{
	isFullScreen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	DialogContainerFullscreen: {
		transform: 'unset !important',
	},
	DialogContentRow: {
		position: 'relative',
		paddingBottom: 0,
		paddingTop: 0,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'stretch',
		paddingLeft: 0,
		paddingRight: 0,
		zIndex: 1500,
	},
	DialogContent: {
		flex: '1 1 auto',
		paddingBottom: 0,
		zIndex: 1500,
		paddingTop: 0,
		overflowY: 'auto',
	},
	FixedBackgroundHolder: {
		backgroundSize: '100vw 100vh',
		backgroundPosition: '0 0',
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1400,
	},
	Paper: {
		backgroundSize: '100vw 100vh',
		minWidth: 'min(70%, 600px)'
	},
	paperFullScreen: {
		alignSelf: 'start',
	},
	ModalRootFullscreen: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 'unset',
	},
	Header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1600,
	},
	HeaderFullscreen: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1600,
	},
	TitleBarFullscreen: {
		backgroundSize: '100vw 100vh',
		backgroundPosition: '0 0',
	},
	FooterBarFullscreen: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1600,
		backgroundSize: '100vw 100vh',
		backgroundPosition: 'bottom left 0',
	},
	TitleBar: {
		[theme.breakpoints.down('sm')]: {
			height: topBarHeightMobile,
		},
		[theme.breakpoints.up('md')]: {
			height: topBarHeight,
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingRight: 20,
		paddingLeft: 20,
	},
	TitleBarContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	TitleBarTitle: {
		marginLeft: 'auto !important',
		marginRight: 'auto !important',
		maxWidth: 'calc(100% - 56px)',
		textAlign: 'center',
		lineHeight: 1.3,
	},
	CloseButton: ({isFullScreen}) => ({
		position: 'absolute',
		...isFullScreen
			?
			{
				left: 4,
			}
			:
			{
				right: 4,
			}
	}),
	TitleOrFooterSpacer: {
		[theme.breakpoints.down('sm')]: {
			height: topBarHeightMobile,
		},
		[theme.breakpoints.up('md')]: {
			height: topBarHeight,
		},
		flex: '0 0 auto',
	},
	FullscreenDialogContentContainer: {
		display: 'fixed',
	},
	FooterBar: {
		[theme.breakpoints.down('sm')]: {
			height: topBarHeightMobile,
		},
		[theme.breakpoints.up('md')]: {
			height: topBarHeight,
		},
		display: 'flex',
		flexDirection: 'column',
	},
	DialogActions: {
		[theme.breakpoints.down('sm')]: {
			marginTop: 8,
			marginBottom: 8,
			marginLeft: 20,
			marginRight: 20,
		},
		[theme.breakpoints.up('md')]: {
			marginTop: 12,
			marginBottom: 12,
			marginLeft: 20,
			marginRight: 20,
		},
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: 0
	},
	closeIcon: {},
	backdropFullScreen: {},
	content: {},
}));

export interface PageDialogProps
{
	/**
	 * The title displayed at the top of the {@link PageDialog}. If left {@code undefined}, there
	 * will be no title bar in the dialog, but the close button will still be there.
	 */
	title?: string | ReactNode,

	/**
	 * Callback for when the backdrop, close button or escape key are clicked/touched/pressed. This
	 * callback is called before any closing animation happens. Use {@link #onExited} for a callback
	 * to be called _after_ the closing animation happens.
	 */
	onClose: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void,

	/**
	 * When the dialog has fully excited (so after its exit animation has happened)
	 */
	onExited?: () => void,
	dialogActions?: React.ReactNode,
	classes?: Partial<ClassNameMap<'closeIcon' | 'content' | 'DialogContent' | 'Paper' | 'paperFullScreen' | 'backdropFullScreen'>>;

	/**
	 * The {@link PageDialog} will take its {@link PageDialogProps#maxWidth} even if the content does
	 * not require it.
	 */
	fullWidth?: boolean;

	open?: boolean;

	/**
	 * The maximum width, i.e. the {@link PageDialog} will not become wider than this width, unless the
	 * value is {@code false}.
	 */
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
	BackdropProps?: Partial<BackdropProps>;

	background?: Property.Background;
	contentPaddingTop?: Property.MarginTop;
	contentPaddingBottom?: Property.MarginBottom;
	contentPaddingLeft?: Property.MarginLeft;
	contentPaddingRight?: Property.MarginRight;

	forceScreenMode?: 'fullScreen' | 'modal'
	maxModalHeightCss?: string
	hideCloseButton?: boolean
	id?: string
}

export const PageDialog: FC<PageDialogProps> =
	(
		{
			classes: classesProp,
			title,
			onClose,
			onExited,
			dialogActions,
			fullWidth,
			open = true,
			maxWidth = 'lg',
			children,
			BackdropProps,
			background = panelGradient,
			contentPaddingTop = 20,
			contentPaddingBottom = 20,
			contentPaddingLeft = 20,
			contentPaddingRight = 20,
			forceScreenMode,
			maxModalHeightCss,
			hideCloseButton = false,
			id,
		}
	) =>
	{
		const contentRef = useRef(null);
		const screenWidth = useScreenWidth();
		const isSmallScreen = useMemo(() => screenWidth === 'xs', [screenWidth]);
		const isFullScreen = forceScreenMode !== undefined
			? forceScreenMode === 'fullScreen'
			: isSmallScreen;
		const defaultClasses = useStyles({isFullScreen});

		const classes = useMemo(
			() =>
			{
				const mergedClasses = Object.assign({}, defaultClasses);

				if (classesProp)
				{
					Object.keys(mergedClasses).forEach(
						key =>
						{
							const foundProperty = classesProp[key];

							if (foundProperty)
							{
								mergedClasses[key] = `${mergedClasses[key]} ${foundProperty}`;
							}
						},
					);
				}

				return mergedClasses;
			},
			[classesProp, defaultClasses]
		);

		const TransitionProps = useMemo(() =>
		{
			return {
				onExited: onExited,
			}
		}, [onExited]);

		return <Dialog
			fullScreen={isFullScreen}
			open={open}
			onClose={onClose}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			classes={{
				paper: classes.Paper,
				paperFullScreen: classes.paperFullScreen,
				root: isFullScreen ? classes.ModalRootFullscreen : undefined,
				container: isFullScreen ? classes.DialogContainerFullscreen : undefined,
			}}
			PaperProps={{
				style: {
					background: background,
					maxHeight: maxModalHeightCss,
				},
				id: id,
			}}
			transitionDuration={250}
			TransitionComponent={Grow as React.ComponentType<TransitionProps>}
			TransitionProps={TransitionProps}
			BackdropProps={{
				className: classes.backdropFullScreen,
				...BackdropProps
			}}
			scroll="paper"
		>
			<div
				className={(isFullScreen ? classes.HeaderFullscreen : classes.Header)
				+ (isFullScreen && title !== undefined ? ' ' + classes.TitleBarFullscreen : '')}
				style={isFullScreen && title !== undefined ? {
					background: background,
				} : {}}
			>
				<DialogTitle
					classes={{root: classes.TitleBar}}
					disableTypography
				>
					<div className={classes.TitleBarContent}>
						{
							typeof title === 'string'
								? <Typography
									variant="h1"
									classes={{
										root: classes.TitleBarTitle,
									}}
								>
									{title}
								</Typography>
								: title
						}
						{!hideCloseButton && <IconButton
							onClick={onClose}
							className={classes.CloseButton}
						>
							{
								isFullScreen
									?
									<ArrowBack
										className={classes.closeIcon}
										id="arrow-back-icon"
									/>
									:
									<Close
										className={classes.closeIcon}
										id="close-icon"
									/>
							}

						</IconButton>}
					</div>
				</DialogTitle>
				{
					title !== undefined
						?
						<Divider />
						:
						<></>
				}
			</div>
			{
				title !== undefined
					?
					<>
						<div className={classes.TitleOrFooterSpacer}/>
					</>
					:
					<></>
			}
			{
				isFullScreen
					?
					<>
						<div
							className={classes.FixedBackgroundHolder}
							style={{
								background: background,
							}}
						/>
					</>
					:
					<></>
			}
			<DialogContent
				className={classes.DialogContentRow}
			>
				<CenteredPageContent>
					<div
						className={classes.DialogContent}
						ref={contentRef}
						style={{
							paddingLeft: contentPaddingLeft,
							paddingRight: contentPaddingRight,
						}}
					>
						<div
							className={classes.content}
							style={{
								marginTop: contentPaddingTop,
								paddingBottom: contentPaddingBottom,
							}}
						>
							{children}
						</div>
					</div>
				</CenteredPageContent>
				<BottomScrollIndicator
					elementRef={contentRef}
					updateOnWindowResize
				/>
			</DialogContent>
			{
				isFullScreen && dialogActions !== undefined
					?
					<>
						<div className={classes.TitleOrFooterSpacer}/>
					</>
					:
					<></>
			}
			{
				dialogActions !== undefined
					?
					<div
						className={classes.FooterBar
						+ (isFullScreen ? ' ' + classes.FooterBarFullscreen : '')}
						style={isFullScreen ? {
							background: background,
						} : {}}
					>
						<Divider />
						<CenteredPageContent>
							<DialogActions
								className={classes.DialogActions}
							>
								{dialogActions}
							</DialogActions>
						</CenteredPageContent>
					</div>
					:
					<></>
			}
		</Dialog>;
	};
