import { ComponentClass } from 'react';
import { AgeVerificationVoucher } from './Component/Page/AgeVerificationVoucher/AgeVerificationVoucher';
import { Bill } from './Component/Page/Bill/Bill';
import { AnnouncementDialog } from './Component/Page/Business/Announcement/AnnouncementDialog';
import { Business } from './Component/Page/Business/Business';
import { BusinessNavigationMenu } from './Component/Page/Business/BusinessNavigationMenu';
import { ComoRewardDialog } from './Component/Page/Business/Como/Reward/ComoRewardDialog';
import { ComoRewards } from './Component/Page/Business/Como/Rewards/ComoRewards';
import { HistoryDialog } from './Component/Page/Business/History/Dialog/HistoryDialog';
import { MenuWrapper } from './Component/Page/Business/Menu/MenuWrapper';
import { OrderBuilderDialog } from './Component/Page/Business/OrderBuilder/dialog/OrderBuilderDialog';
import { OrderBuilderSidebar } from './Component/Page/Business/OrderBuilder/sidebar/OrderBuilderSidebar';
import { ProductDialog } from './Component/Page/Business/Product/Dialog/ProductDialog';
import { ProductRecommendationDialog } from './Component/Page/Business/Product/ProductRecommendation/Dialog/ProductRecommendationDialog';
import { Entrance } from './Component/Page/Entrance/Entrance';
import { EntranceNavigationMenu } from './Component/Page/Entrance/EntranceNavigationMenu';
import Login from './Component/Page/Login/Login';
import Manager from './Component/Page/Manager/Manager';
import { ProfileDialog } from './Component/Page/Profile/Dialog/ProfileDialog';
import Scanner from './Component/Page/Scanner/Scanner';
import { Screens } from './Constants/ScreenConstants';

export function getScreenByIdMap(): Map<string, ComponentClass>
{
	const screenById = new Map<string, any>();
	screenById.set(Screens.Entrance, Entrance);
	screenById.set(Screens.Scanner, Scanner);
	screenById.set(Screens.Business, Business);
	screenById.set(Screens.EntranceNavigationMenu, EntranceNavigationMenu);
	screenById.set(Screens.BusinessNavigationMenu, BusinessNavigationMenu);
	screenById.set(Screens.BusinessOrderBuilderSidebar, OrderBuilderSidebar);
	screenById.set(Screens.Menu, MenuWrapper);
	screenById.set(Screens.ComoRewards, ComoRewards);
	screenById.set(Screens.ComoReward, ComoRewardDialog);
	screenById.set(Screens.Product, ProductDialog);
	screenById.set(Screens.Announcement, AnnouncementDialog);
	screenById.set(Screens.ProductRecommendations, ProductRecommendationDialog);
	screenById.set(Screens.Profile, ProfileDialog);
	screenById.set(Screens.History, HistoryDialog);
	screenById.set(Screens.OrderBuilder, OrderBuilderDialog);
	screenById.set(Screens.Login, Login);
	screenById.set(Screens.Manager, Manager);
	screenById.set(Screens.Bill, Bill);
	screenById.set(Screens.AgeVerificationVoucher, AgeVerificationVoucher);
	return screenById;
}
