import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ReactNode } from 'react';
import { Color } from '../../../Api/Other/Color';
import { ExtendedMenuEntryType } from '../../../Api/Product/ExtendedMenuEntryType';
import { MenuEntryType } from '../../../Api/Product/MenuEntryType';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { getParagraphStylesWithoutTopAndBottomMargin } from '../../../Util/Html/getParagraphStylesWithoutTopAndBottomMargin';
import { ListItemWithImage } from './list_item/ListItemWithImage';
import { ListItemWithoutImage } from './list_item/ListItemWithoutImage';
import { TileWithImage } from './tile/TileWithImage';
import { TileWithoutImage } from './tile/TileWithoutImage';

export const menuEntryStyles =
	() =>
		createStyles({
			root: {
				height: '100%',
				width: '100%',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				flex: '1 0 auto',
				minHeight: 55,
			},
			textOnlyTile: {
				height: '100%',
				width: '100%',
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
				textAlign: 'left',
				display: 'flex',
				flex: '1 1 auto',
				flexDirection: 'column',
				justifyContent: 'center',
			},
			textOnlyListItem: {
				height: '100%',
				width: '100%',
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
				textAlign: 'left',
				display: 'flex',
				flex: '1 1 auto',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			imageListItem: {
				height: '100%',
				width: '100%',
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'stretch',
			},
			imageListItemImageContainer: {
				width: '50px',
				padding: 6,
			},
			imageListItemImage: {
				flex: '0 0 auto',
				width: '100%',
				height: '100%',
				borderRadius: 3,
				backgroundSize: 'cover',
			},
			imageListItemText: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 6,
				paddingRight: 16,
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				textAlign: 'left',
			},
			badgeContainer: {
				position: 'absolute',
				top: 8,
				right: 8,
				display: 'flex',
				flexFlow: 'row-reverse wrap',
				alignItems: 'center'
			},
			badgeWrapper: {
				marginLeft: 5,
				marginBottom: 5,
			},
			listItemBadgeWrapper: {
				marginRight: 5,
				marginTop: 5,
				display: 'inline-block',
				verticalAlign: 'middle',
			},
			tileBarRoot: {},
			tileBarTitleWrap: {},
			tileBarTitle: {},
			title: {
				flex: '0 1 auto',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
			price: {
				flex: '0 0 auto',
			},
			description: {
				display: '-webkit-box',
				'-webkit-line-clamp': 2,
				'-webkit-box-orient': 'vertical',
				overflow: 'hidden',
				fontWeight: 100,
				...getParagraphStylesWithoutTopAndBottomMargin(),
			},
		});

export interface ImageProps
{
	imageURL: string;
	backgroundColor?: Color;
	containImage?: boolean;
	imageHasTextBackdrop?: boolean;
	imageIsTextContrastColorDark?: boolean;
	imageTextBackdropColor?: Color;
	imageTextColor?: Color;
}

export interface MenuTileProps
{
	minHeight?: number | string;
	minImageHeight?: number;
	minImageWidth?: number;
	backgroundColor?: Color;
	textColor?: Color;
	description?: string;
	descriptionInHtml?: string;
	image: ImageProps | undefined;
	type?: ExtendedMenuEntryType;
	primaryBadges?: ReactNode[];
	secondaryBadges?: ReactNode[];
	title: string;
	price?: string;
	textHorizontallyCenteredWhenNoPictureAndTile?: boolean;
	onClick?: () => void;
	disabled?: boolean;
}

@observer
class MenuEntry extends React.Component<MenuTileProps & WithStyles<typeof menuEntryStyles>>
{
	render(): React.ReactNode
	{
		const {
			image,
		} = this.props;
		const hasImage = image !== undefined;

		if (hasImage)
		{
			return this.renderWithImage(image!);
		}
		else
		{
			return this.renderTextOnly();
		}
	}

	renderWithImage(
		image: ImageProps
	): React.ReactNode
	{
		const menuEntryType = this.props.type !== undefined ? this.props.type : 'TILE';

		if (menuEntryType === 'TILE' || menuEntryType === 'TILE_V2')
		{
			return <TileWithImage
				type={this.props.type}
				onClick={this.props.onClick}
				primaryBadges={this.props.primaryBadges}
				secondaryBadges={this.props.secondaryBadges}
				price={this.props.price}
				image={image}
				backgroundColor={this.backgroundColor}
				minHeight={this.props.minHeight}
				minImageHeight={this.props.minImageHeight}
				minImageWidth={this.props.minImageWidth}
				title={this.props.title}
				disabled={this.props.disabled}
			/>;
		}
		else
		{
			return <ListItemWithImage
				className={this.props.classes.root}
				minHeight={this.props.minHeight}
				backgroundColor={this.backgroundColor}
				textColor={this.props.textColor}
				primaryBadges={this.props.primaryBadges}
				secondaryBadges={this.props.secondaryBadges}
				onClick={this.props.onClick}
				price={this.props.price}
				type={this.props.type as MenuEntryType}
				image={image}
				title={this.props.title}
				description={this.props.description}
				descriptionInHtml={this.props.descriptionInHtml}
				disabled={this.props.disabled}
			/>;
		}
	}

	renderTextOnly(): React.ReactNode
	{
		const {
			type = 'TILE',
			textColor,
		} = this.props;
		const finalTextColor = textColor ?? BrandingService.computeContrastTextColorFor(this.backgroundColor);

		if (type === 'TILE' || type === 'TILE_V2')
		{
			return <TileWithoutImage
				{...this.props}
				backgroundColor={this.backgroundColor}
				textColor={finalTextColor}
			/>;
		}
		else
		{
			return <ListItemWithoutImage
				{...this.props}
				backgroundColor={this.backgroundColor}
				textColor={finalTextColor}
			/>;
		}
	}

	@computed
	private get backgroundColor(): Color
	{
		return this.props.backgroundColor
			?? new Color(255, 255, 255, 1);
	}
}

export default withStyles(menuEntryStyles)(MenuEntry);
