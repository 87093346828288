import { BaseStore } from '@intentic/ts-foundation';
import { makeObservable, override } from 'mobx';
import { Bridge } from '../../Bridge/Bridge';
import { AuthenticationService } from '../../Service/Authentication/AuthenticationService';
import { BrandingService } from '../../Service/BrandingInformation/BrandingService';
import { NotificationService } from '../../Service/NotificationService/NotificationService';

export class RootStore extends BaseStore
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly bridge: Bridge;
	public readonly authenticationService: AuthenticationService;
	public readonly notificationService: NotificationService;
	public readonly brandingService: BrandingService;

	/*---------------------------------------------------------------*
	*                          Constructors                         *
	*---------------------------------------------------------------*/

	constructor(
		bridge: Bridge,
		authenticationService: AuthenticationService,
		brandingService: BrandingService,
	)
	{
		super();

		makeObservable<RootStore>(this, {
			initialize: override,
		});

		this.bridge = bridge;
		this.authenticationService = authenticationService;
		this.notificationService = new NotificationService();
		this.brandingService = brandingService;
	}

	/*---------------------------------------------------------------*
	 *                         Initialization                        *
	 *---------------------------------------------------------------*/

	async initialize(): Promise<void>
	{
		const initialUrl = await this.bridge.linking.getInitialUrl();
		await this.bridge.navigator.routeFromUrl(initialUrl);
	}
}