import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { StorageVars } from '../../Constants/StorageConstants';

type QueryParameterInterceptorHandler = (value: string | undefined) => void;

export const QueryParameterInterceptor: FC =
	({
		children,
	}) =>
	{
		const [didIntercept, setDidIntercept] = useState(false);

		const handlers = useMemo<Map<string, QueryParameterInterceptorHandler>>(() =>
		{
			return new Map([
				[StorageVars.ClearOrderOptionsAfterOrder, value => handleStoredBoolean(StorageVars.ClearOrderOptionsAfterOrder, value)],
				[StorageVars.ClearShoppingCartAfterReturning, value => handleStoredBoolean(StorageVars.ClearShoppingCartAfterReturning, value)],
				[StorageVars.HideTopBar, value => handleStoredBoolean(StorageVars.HideTopBar, value)],
				[StorageVars.IsKioskMode, value => handleStoredBoolean(StorageVars.IsKioskMode, value)],
				[StorageVars.LowPerformance, value => handleStoredBoolean(StorageVars.LowPerformance, value)],
				[StorageVars.NavigateToBusinessRootAfterOrder, value => handleStoredBoolean(StorageVars.NavigateToBusinessRootAfterOrder, value)],
				[StorageVars.OrderHistoryHidden, value => handleStoredBoolean(StorageVars.OrderHistoryHidden, value)],
				[StorageVars.UserProfileHidden, value => handleStoredBoolean(StorageVars.UserProfileHidden, value)],
			]);
		}, []);

		const parameters = useMemo(() => new URLSearchParams(window.location.search), []);

		useEffect(() =>
		{
			if (!didIntercept)
			{
				Array
					.from(parameters.entries())
					.filter(([key]) => handlers.has(key))
					.forEach(([key, value]) => handlers.get(key)!(value));

				setDidIntercept(true);
			}
		}, [didIntercept, handlers, parameters]);

		if (didIntercept)
		{
			return <>{children}</>;
		}
		else
			return null;
	};

function handleStoredBoolean(storageKey: string, value: string | undefined): void
{
	// Only change if explicitly provided
	if (value === '0' || value === 'false')
		window.localStorage.removeItem(storageKey);
	else if (value === '1' || value === 'true')
		window.localStorage.setItem(storageKey, 'true');
}
