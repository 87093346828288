import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable } from 'mobx';
import { Account } from '../../../Api/Account/Account';
import { postAny } from '../../../Util/Api';

export class LoginCardStore extends BaseStore
{

    private readonly onLogin: (account: Account) => void;
    private readonly onError: (message: string) => void;
    public emailAddress = '';
    public password = '';

    constructor(
        onLogin: (account: Account) => void,
        onError: (message: string) => void
    )
    {
        super();

        makeObservable(
            this,
            {
                emailAddress: observable,
                password: observable,
                isLoginAllowed: computed,
                setEmailAddress: action.bound,
                setPassword: action.bound,
                attemptLogin: action.bound,
            },
        );

        this.onLogin = onLogin;
        this.onError = onError;
    }

    get isLoginAllowed(): boolean
    {
        return this.emailAddress.length > 0 && this.password.length > 0;
    }

    setEmailAddress(emailAddress: string): void
    {
        this.emailAddress = emailAddress;
    }

    setPassword(password: string): void
    {
        this.password = password;
    }

    attemptLogin(): void
    {
        if (this.isLoginAllowed)
        {
            postAny(
                '/public/business/waiter/id',
                {
                    email_address: this.emailAddress,
                    password: this.password,
                },
            )
                .then(response => {
                    if (isNaN(parseInt(response, 10)))
                    {
                        if (response === 'false_email_address')
                        {
                            this.onError('E-mail adres incorrect.');
                        }
                        else if (response === 'false_password')
                        {
                            this.onError('Wachtwoord incorrect.');
                        }
                        else
                        {
                            this.onError('Een onbekende fout heeft plaatsgevonden.');
                        }
                    }
                    else
                    {
                        this.onLogin(new Account(response, this.password, null));
                    }
                });
        }
    }
}