import * as React from 'react';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useWebClient } from '../../Bridge/Client/WebClientProvider';
import { AuthenticationService } from '../../Service/Authentication/AuthenticationService';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { useStorage } from '../Root/StorageContextProvider';

interface Context
{
	authenticationService: AuthenticationService
	didInitialize: boolean
	initializationError: Error | undefined
}

const ContextRef = createContext<Context | undefined>(undefined);

export const AuthenticationProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const client = useWebClient(true);
		const storage = useStorage(true);
		const [didInitialize, setDidInitialize] = useState(false);
		const [initializationError, setInitializationError] = useState<Error>();
		const authenticationService = useMemo(
			() => new AuthenticationService(
				storage,
				client,
			),
			[client, storage],
		);

		useEffect(
			() => {
				(async () => {
					try
					{
						await authenticationService.initialize();
						setDidInitialize(true);
					}
					catch (error)
					{
						console.error(`${error}`);
						setInitializationError(error);
					}
				})()
			},
			[authenticationService]
		)
		return <ContextRef.Provider
			value={useMemo(() => ({
				authenticationService,
				didInitialize,
				initializationError,
			}), [authenticationService, initializationError, didInitialize])}
		>
			{children}
		</ContextRef.Provider>;
	};

function useThisContext(): Context
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useAuthenticationService outside of AuthenticationProvider'
				);
			return context;
		},
		[context]
	);
}

export function useAuthenticationService(): AuthenticationService
{
	return useThisContext().authenticationService;
}

export function useDidAuthenticationServiceInitialize(): boolean
{
	return useThisContext().didInitialize;
}

export function useAuthenticationServiceInitializationError(): Error | undefined
{
	return useThisContext().initializationError;
}