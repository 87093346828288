import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { PageDialog } from '../../../../../UI/PageDialog';
import { OrderBuilder } from '../../OrderBuilder';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const useStyles = makeStyles({
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexGrow: 1,
	},
	DialogContent: {
		display: 'flex',
	},
})

interface OrderBuilderPageDialogProps
{
	store: OrderBuilderStore;
}

export const OrderBuilderPageDialog: FC<OrderBuilderPageDialogProps> =
	({
		store,
	}) =>
	{
		const translate = useTranslate();
		const close = useObserver(() => store.close);
		const classes = useStyles();

		return <PageDialog
			classes={classes}
			title={translate('Client-ShoppingCart-Title')}
			onClose={close}
		>
			<OrderBuilder
				store={store}
				flexGrow
			/>
		</PageDialog>;
	};
