import { BaseStore } from '@intentic/ts-foundation';
import { computed, makeObservable } from 'mobx';
import { Account } from '../../../Api/Account/Account';
import { InfoSnackbarStore } from '../../UI/InfoSnackbar/InfoSnackbarStore';
import { InfoSnackbarVariant } from '../../UI/InfoSnackbar/InfoSnackbarVariant';
import { LoginCardStore } from './LoginCardStore';

export class LoginStore extends BaseStore
{
    private readonly onLogin: (account: Account) => void;
    public readonly onClose: () => void;

    constructor(
        onLogin: (account: Account) => void,
        onClose: () => void,
    )
    {
        super();

        makeObservable(
            this,
            {
                loginCardStore: computed,
                infoSnackbarStore: computed,
            }
        )

        this.onLogin = onLogin;
        this.onClose = onClose;
    }

    get loginCardStore(): LoginCardStore
    {
        return new LoginCardStore(
            this.onLogin,
            message => this.infoSnackbarStore.showMessage({
                message: message,
                variant: InfoSnackbarVariant.error,
            }),
        );
    }

    get infoSnackbarStore(): InfoSnackbarStore
    {
        return new InfoSnackbarStore();
    }
}
