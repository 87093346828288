import * as Sentry from '@sentry/browser';
import { makeAutoObservable, reaction } from 'mobx';
import { Account, AccountProfile } from '../../Api/Account/Account';
import { AccountRegistrationResponse } from '../../Api/Account/AccountRegistrationResponse';
import { Client } from '../../Bridge/Client/Client';
import { Storage } from '../../Bridge/Storage/Storage';
import { StorageVars } from '../../Constants/StorageConstants';
import { fetchAny, postAny } from '../../Util/Api';
import { getBackendOSValue } from '../../Util/Api/getBackendOSValue';
import { setDefaultApiHeader } from '../../Util/Api/setDefaultApiHeader';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';

export class AuthenticationService
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly storage: Storage;
	private readonly client: Client;
	public account: Account | undefined;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		storage: Storage,
		client: Client,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.storage = storage;
		this.client = client;

		reaction(() => this.account, this.updateSentryScope);
	}

	/*---------------------------------------------------------------*
	 *                         Initialization                        *
	 *---------------------------------------------------------------*/

	/**
	 * Initializes the current user's account:
	 * <ol>
	 *     <li>Fetches account descriptor from storage</li>
	 *     <li>Attempts to parse account descriptor</li>
	 *     <lin>Attempts to verify account using the API, registering the account if new</li>
	 * </ol>
	 *
	 * @return account
	 * @throws {@link IllegalStateException} iff account descriptor cannot be parsed as an {@link Account}
	 */
	async initialize(): Promise<Account>
	{
		const accountDescriptor = await this.storage.get(StorageVars.Account);

		if (accountDescriptor)
		{
			const account = AccountProfile.deserializeSingular(JSON.parse(accountDescriptor));

			if (account)
			{
				return await this.verifyAccount(account) ? this.setAccount(account) : this.registerAccount();
			}
			else
			{
				throw new IllegalStateException(`Account ${accountDescriptor} is invalid.`);
			}
		}
		else
		{
			return this.registerAccount();
		}
	}

	/*---------------------------------------------------------------*
	 *                     Business logic: public                    *
	 *---------------------------------------------------------------*/

	/**
	 * Registers the current {@link Account} at the API
	 *
	 * @return <ul>
	 *      <li>Registed {@link Account} if successful</li>
	 *      <li>'Invalid account registered' rejection if unsuccessful</li>
	 * </ul>
	 */
	async registerAccount(): Promise<Account>
	{
		const response: AccountRegistrationResponse = await fetchAny(
			'/business/client/register',
			{
				os: getBackendOSValue(this.client),
				osVersion: '1',
			},
		);

		const account = new Account(
			response.key,
			response.phrase,
			null,
		);

		return await this.verifyAccount(account) ? Promise.resolve(account) : Promise.reject('Invalid account registered');
	}

	/**
	 * Verifies an {@link Account} using the API
	 *
	 * @param account {@link Account} to verify
	 * @return whether or not the account can be verified
	 */
	async verifyAccount(account: Account): Promise<boolean>
	{
		const response: boolean = await postAny(
			'/business/client/account/verify',
			{
				user_id: account.key,
				password: account.token,
				os: getBackendOSValue(this.client),
			},
		);

		if (response)
		{
			await this.setAccount(account);
		}

		return Promise.resolve(response);
	}

	/*---------------------------------------------------------------*
	 *                    Business logic: private                    *
	 *---------------------------------------------------------------*/

	/**
	 * Sets the {@link Account}
	 *
	 * @param account account to set
	 * @return set account
	 */
	private async setAccount(account: Account): Promise<Account>
	{
		this.account = account;

		setDefaultApiHeader('Authorization-Key', account.key?.toString());
		setDefaultApiHeader('Authorization-Phrase', account.token);

		return this.persistAccount(account);
	}

	/**
	 * Persists the {@link Account}
	 *
	 * @param account account to set
	 * @return persisted account
	 */
	private async persistAccount(account: Account): Promise<Account>
	{
		return this.storage
			.set(StorageVars.Account, JSON.stringify(account))
			.then(() => Promise.resolve(account));
	}

	/**
	 * Updates the scope of send Sentry exceptions from this client, adding the current user's ID
	 */
	private updateSentryScope()
	{
		Sentry.configureScope(
			scope =>
			{
				scope.setUser({
					id: this.account?.key?.toString(),
				});
			},
		);
	}
}
