import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, IObservableArray, makeObservable, observable, ObservableMap } from 'mobx';
import { Announcement } from '../../../../Api/Business/Announcement';
import { Business } from '../../../../Api/Business/Business';
import { Place } from '../../../../Api/Business/Place';
import { CustomerCard } from '../../../../Api/customer_card/CustomerCard';
import { CartProfile } from '../../../../Api/Order/Cart/Cart';
import { Order } from '../../../../Api/Order/Order';
import { PaymentIssuer } from '../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor, TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../Api/Payment/PaymentMethodDescriptor';
import { Bridge } from '../../../../Bridge/Bridge';
import { Client } from '../../../../Bridge/Client/Client';
import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { getFromLocalStorage } from '../../../../Bridge/Storage/local/getFromLocalStorage';
import { setInLocalStorage } from '../../../../Bridge/Storage/local/setInLocalStorage';
import { Screens } from '../../../../Constants/ScreenConstants';
import { StorageVars } from '../../../../Constants/StorageConstants';
import { AdyenCheckoutData, isPaymentData } from '../../../../lib/adyen/AdyenCheckoutData';
import { CurrentAgeVerificationService } from '../../../../Service/CurrentAgeVerification/CurrentAgeVerificationService';
import { CurrentOrderService } from '../../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../../Service/CurrentPlace/CurrentPlaceService';
import { LocationService } from '../../../../Service/Location/LocationService';
import { placeProductOrder } from '../../../../Service/OrderService/Api/Client/placeProductOrder';
import { OrderService } from '../../../../Service/OrderService/OrderService';
import { fetch } from '../../../../Util/Api';
import { getBackendOSValue } from '../../../../Util/Api/getBackendOSValue';
import { ClockService } from '../../../../Util/clock/ClockService';
import { IllegalStateException } from '../../../../Util/Exception/IllegalStateException';
import { isCompletedOrderState } from '../../../../Util/Orders/isCompletedOrderState';
import { StoredVariable } from '../../../../Util/StoredVariable';
import { EntranceStore } from '../../Entrance/EntranceStore';
import { BusinessStore } from '../BusinessStore';
import { CouponFormStore } from '../Coupon/CouponForm/CouponFormStore';
import { DeliveryFormStore } from '../Delivery/DeliveryFormStore';
import { OrderCommentStore } from '../OrderComment/OrderCommentStore';
import { SchedulerStore } from '../Scheduler/SchedulerStore';
import { ShoppingCartStore } from '../ShoppingCart/ShoppingCartStore';
import { getAnnouncementKey } from './segments/announcements/getAnnouncementKey';

export type OrderBuilderForm = 'shopping-cart' | 'client-name' | 'payment-method' | 'coupon' | 'scheduler' | 'comment' | 'password';

export class OrderBuilderStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	bridge: Bridge;
	currentPlaceService: CurrentPlaceService;
	currentOrderService: CurrentOrderService;
	currentAgeVerificationService: CurrentAgeVerificationService;
	locationService: LocationService;
	businessStore: BusinessStore;
	shoppingCartStore: ShoppingCartStore;
	orderService: OrderService;
	close: (force?: boolean, uuidOfScreenInstantiationToPop?: string) => Promise<any>;
	clientNameStore: DeliveryFormStore;
	couponFormStore: CouponFormStore | null;
	schedulerStore: SchedulerStore | null;
	focusedForms = observable.set<OrderBuilderForm>();
	client: Client;
	showValidation: boolean;
	validationErrors: ObservableMap<OrderBuilderForm, IObservableArray<string>>;
	public readonly orderCommentStore: OrderCommentStore | undefined;
	inMemoryUserAcknowledgements = observable.array<string>();
	public readonly persistedUserAcknowledgements: StoredVariable<string[]>;

	password?: string;
	customerCard?: CustomerCard;
	ageVerificationChallengeId?: string;

	paymentMethodHasError: boolean
	hasInteractedWithPaymentMethods: boolean
	paymentSessionHasError: boolean
	passwordHasError: boolean
	customerCardHasError: boolean

	// ------------------------ Constructor -------------------------

	constructor(
		bridge: Bridge,
		currentPlaceService: CurrentPlaceService,
		currentOrderService: CurrentOrderService,
		currentAgeVerificationService: CurrentAgeVerificationService,
		clockService: ClockService,
		localizer: Localizer,
		locationService: LocationService,
		entranceStore: EntranceStore,
		businessStore: BusinessStore,
		shoppingCartStore: ShoppingCartStore,
		orderService: OrderService,
		close: (force?: boolean, uuidOfScreenInstantiationToPop?: string) => Promise<any>,
		client: Client,
	)
	{
		super();

		makeObservable<OrderBuilderStore, 'clientNameHasForcedFocus' | 'couponHasForcedFocus' | 'schedulerHasForcedFocus' | 'paymentMethodHasForcedFocus'>(
			this,
			{
				bridge: observable,
				currentPlaceService: observable,
				currentOrderService: observable,
				currentAgeVerificationService: observable,
				locationService: observable,
				businessStore: observable,
				shoppingCartStore: observable,
				orderService: observable,
				close: observable,
				clientNameStore: observable,
				couponFormStore: observable,
				schedulerStore: observable,
				focusedForms: observable,
				client: observable,
				showValidation: observable,
				validationErrors: observable,
				inMemoryUserAcknowledgements: observable,
				password: observable,
				customerCard: observable,
				business: computed,
				place: computed,
				hasDeliveryForm: computed,
				shoppingCartHasFocus: computed,
				paymentMethodHasFocus: computed,
				clientNameHasFocus: computed,
				couponHasFocus: computed,
				schedulerHasFocus: computed,
				commentFormHasFocus: computed,
				passwordFormHasFocus: computed,
				needsPayment: computed,
				clientNameHasForcedFocus: computed,
				couponHasForcedFocus: computed,
				schedulerHasForcedFocus: computed,
				paymentMethodHasForcedFocus: computed,
				inTerminalPayment: computed,
				showCouponForm: computed,
				showScheduler: computed,
				showCommentForm: computed,
				hasLegalConsent: computed,
				announcementsToAcknowledge: computed,
				userHasAcknowledgedRequiredAnnouncements: computed,
				hasValidationErrors: computed,
				orderLinesDescriptor: computed,
				paymentMethodHasError: observable,
				paymentSessionHasError: observable,
				passwordHasError: observable,
				customerCardHasError: observable,
				hasInteractedWithPaymentMethods: observable,
				setPaymentMethodHasError: action.bound,
				setPaymentSessionHasError: action.bound,
				addUserAcknowledgement: action.bound,
				removeUserAcknowledgement: action.bound,
				validateFormAndSendUserBackIfNecessary: action.bound,
				attemptPlaceOrder: action.bound,
				setShowValidation: action.bound,
				placeOrder: action.bound,
				doAfterOrderPlacement: action.bound,
				doAfterOrderComplete: action.bound,
				closeOrderBuilder: action.bound,
				setShoppingCartFocusHasFocus: action.bound,
				setPaymentMethodHasFocus: action.bound,
				setClientNameHasFocus: action.bound,
				setCouponHasFocus: action.bound,
				setSchedulerHasFocus: action.bound,
				setCommentFormHasFocus: action.bound,
				setPasswordFormHasFocus: action.bound,
				setFocusedForm: action.bound,
				setHasLegalConsent: action.bound,
				setPassword: action.bound,
				setPasswordHasError: action.bound,
				setCustomerCard: action.bound,
				setCustomerCardHasError: action.bound,
				setHasInteractedWithPaymentMethods: action.bound,
			},
		);

		this.bridge = bridge;
		this.currentPlaceService = currentPlaceService;
		this.currentOrderService = currentOrderService;
		this.currentAgeVerificationService = currentAgeVerificationService;
		this.locationService = locationService;
		this.businessStore = businessStore;
		this.shoppingCartStore = shoppingCartStore;
		this.orderService = orderService;
		this.close = close;
		this.client = client;
		this.clientNameStore = new DeliveryFormStore(
			this.currentPlaceService,
			this.currentOrderService,
			this.bridge,
			entranceStore,
			businessStore,
			() => new Promise(resolve => {
				this.setClientNameHasFocus(false);
				resolve();
			}),
		);
		this.couponFormStore = this.currentOrderService.supportsCouponCode
			?
			new CouponFormStore(
				this.bridge,
				this.currentOrderService,
				entranceStore,
				businessStore,
				() => new Promise(resolve => {
					this.setClientNameHasFocus(false);
					resolve();
				}),
			)
			:
			null;
		this.schedulerStore = this.currentOrderService.showScheduler
			?
			new SchedulerStore(
				currentPlaceService,
				currentOrderService,
				localizer,
			)
			:
			null;

		this.showValidation = false;
		this.validationErrors = observable.map<OrderBuilderForm, IObservableArray<string>>();
		this.orderCommentStore = this.currentOrderService.showCommentForm
			?
			new OrderCommentStore(
				currentOrderService,
				currentPlaceService,
				bridge.localizer,
			)
			:
			undefined;

		this.persistedUserAcknowledgements = new StoredVariable<string[]>(
			this.bridge.storage,
			StorageVars.UserAcknowledgedKeys,
			value => value === undefined ? [] : JSON.parse(value),
			value => value === undefined ? undefined : JSON.stringify(value),
		);
		this.persistedUserAcknowledgements.syncWithStorage();

		const customerCard = getFromLocalStorage<CustomerCard>(
			StorageVars.CustomerCard,
			value => value === undefined
				? undefined
				: JSON.parse(value)
			);

		if (customerCard?.businessId === businessStore.business.id)
			this.customerCard = customerCard;
		else
			setInLocalStorage(StorageVars.CustomerCard)

		this.customerCardHasError = false;

		this.password = getFromLocalStorage(
			StorageVars.PlacePassword,
			value => value,
		);

		this.paymentMethodHasError = false;
		this.paymentSessionHasError = false;
		this.hasInteractedWithPaymentMethods = false;
	}

	// ----------------------- Initialization -----------------------

	async initialize(): Promise<void>
	{
		await this.persistedUserAcknowledgements.syncWithStorage();

		await this.clientNameStore.initializeStore();
		await this.couponFormStore?.initializeStore();
	}

	entersUI(isMounted: boolean): void
	{
		if (isMounted)
		{
			if (this.schedulerHasForcedFocus)
			{
				this.setFocusedForm('scheduler');
			}

			if (this.couponHasForcedFocus)
			{
				this.setFocusedForm('coupon');
			}

			if (this.clientNameHasForcedFocus)
			{
				this.setFocusedForm('client-name');
			}

			if (this.paymentMethodHasForcedFocus)
			{
				this.setFocusedForm('payment-method');
			}

			this.setFocusedForm('shopping-cart');

			// Start checking order restrictions when the order builder is visible
			// Note that entersUI is called twice, but we do not want to check the order twice
			if (!this.currentOrderService.doCheckRestrictionsOnCartChange)
			{
				this.currentOrderService.doCheckRestrictionsOnCartChange = true;

				// Check order that has been built so far
				this.currentOrderService.checkOrder();
			}
		}
	}

	exitsUI(isUnmounted: boolean): void
	{
		if (isUnmounted)
		{
			// Stop checking order restrictions when the order builder is invisible
			this.currentOrderService.doCheckRestrictionsOnCartChange = false;

			this.setHasInteractedWithPaymentMethods(false);
		}
	}

	// -------------------------- Computed --------------------------

	get business(): Business
	{
		return this.currentPlaceService.business!;
	}

	get place(): Place
	{
		return this.currentPlaceService.place!;
	}

	get hasDeliveryForm(): boolean
	{
		const deliveryStore = this.clientNameStore;
		return deliveryStore.supportsFirstName
			|| deliveryStore.supportsLastName
			|| deliveryStore.supportsCompanyName
			|| deliveryStore.supportsAddress
			|| deliveryStore.supportsEmail
			|| deliveryStore.supportsPhoneNumber
			|| deliveryStore.supportsDestinationTypeChoice;
	}

	get shoppingCartHasFocus()
	{
		return this.focusedForms.has('shopping-cart') || this.focusedForms.size === 0;
	}

	get paymentMethodHasFocus()
	{
		return this.focusedForms.has('payment-method');
	}

	get clientNameHasFocus()
	{
		return this.focusedForms.has('client-name');
	}

	get couponHasFocus()
	{
		return this.focusedForms.has('coupon');
	}

	get schedulerHasFocus()
	{
		return this.focusedForms.has('scheduler');
	}

	get commentFormHasFocus()
	{
		return this.focusedForms.has('comment');
	}

	get passwordFormHasFocus()
	{
		return this.focusedForms.has('password');
	}

	get needsPayment()
	{
		return this.currentOrderService.needsPayment;
	}

	private get clientNameHasForcedFocus()
	{
		return this.clientNameStore.validation.length !== 0;
	}

	private get couponHasForcedFocus()
	{
		return (this.couponFormStore?.validation.length ?? 0) !== 0;
	}

	private get schedulerHasForcedFocus()
	{
		return (this.schedulerStore?.validation.length ?? 0) !== 0;
	}

	private get paymentMethodHasForcedFocus()
	{
		return !this.hasInteractedWithPaymentMethods;
	}

	get inTerminalPayment(): boolean
	{
		const mostRecentOrder = this.orderService.mostRecentOrder;
		if (mostRecentOrder?.paymentMethodId === 'PIN' || mostRecentOrder?.paymentMethodId === 'Cash')
		{
			if (!isCompletedOrderState(mostRecentOrder.state) && mostRecentOrder.state !== 'voided')
			{
				const paymentState = mostRecentOrder.paymentState;
				return paymentState !== 'paid' && paymentState !== 'failed';
			}
		}
		return false;
	}

	get showCouponForm(): boolean
	{
		return this.couponFormStore != null;
	}

	get showScheduler(): boolean
	{
		const place = this.currentPlaceService.place!;
		return place.orderInFutureSupport !== 'DISALLOWED'
			&& this.currentOrderService.effectiveDestinationType !== undefined;
	}

	get showCommentForm(): boolean
	{
		const place = this.currentPlaceService.place!;
		return place.commentField !== 'DISALLOWED';
	}

	get showCustomerCardForm(): boolean
	{
		const place = this.currentPlaceService.place!;
		return place.customerCardSupport !== 'DISALLOWED';
	}

	get isAgeVerificationNotPassed()
	{
		return this.currentAgeVerificationService.needsAgeVerification
			&& !this.currentAgeVerificationService.isChallengePassed;
	}

	get hasLegalConsent(): boolean
	{
		return this.currentOrderService.hasLegalConsent;
	}

	get announcementsToAcknowledge(): Announcement[]
	{
		return this
			.currentOrderService
			.orderAnnouncementsRequiringUserAcknowledgement;
	}

	get userHasAcknowledgedRequiredAnnouncements(): boolean
	{
		return this
			.announcementsToAcknowledge
			.map(announcement => getAnnouncementKey(announcement))
			.every(key => this.persistedUserAcknowledgements.get().includes(key) || this.inMemoryUserAcknowledgements.includes(key));
	}

	get hasValidationErrors(): boolean
	{
		if (this.shoppingCartStore.validation.length > 0)
		{
			return true;
		}

		if (this.hasDeliveryForm && this.clientNameStore.validation.length > 0)
		{
			return true;
		}

		if (this.couponFormStore && this.couponFormStore.validation.length > 0)
		{
			return true;
		}

		for (let form of ['scheduler'] as OrderBuilderForm[])
		{
			let validationErrors = this.validationErrors.get(form);
			if (validationErrors !== undefined && validationErrors.length > 0)
			{
				return true;
			}
		}

		if (this.orderCommentStore !== undefined && this.orderCommentStore.validationErrors.length > 0)
		{
			return true;
		}

		if (this.passwordHasError)
		{
			return true;
		}

		if (this.customerCardHasError)
		{
			return true;
		}

		if (this.needsPayment && (this.paymentMethodHasError || this.paymentSessionHasError))
		{
			return true;
		}

		if (this.isAgeVerificationNotPassed)
		{
			return true;
		}

		if (!this.hasLegalConsent)
		{
			return true;
		}

		if (!this.userHasAcknowledgedRequiredAnnouncements)
		{
			return true;
		}

		return false;
	}

	get orderLinesDescriptor(): string
	{
		return JSON.stringify(
			this
			.currentOrderService
			.configurations
			.map(
				configuration =>
					({
						type: 'product',
						product_id: configuration.product.id,
						quantity: this.currentOrderService.writeAheadCartContents.get(configuration),
						product_variants: configuration
							.variantConfigurations
							.map(
								variant =>
									({
										product_feature_variant_id: variant.variant.id,
										product_feature_assignment_id: variant.assignment.id,
									})
							),
					})
			)
		);
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	setPaymentMethodHasError(hasError: boolean): void
	{
		this.paymentMethodHasError = hasError;
	}

	setPaymentSessionHasError(hasError: boolean): void
	{
		this.paymentSessionHasError = hasError;
	}

	setPassword(value?: string): void
	{
		if (this.place.passwordSupport === 'ALLOWED')
		{
			this.password = value;

			setInLocalStorage(StorageVars.PlacePassword, value);
		}
		else if (this.place.passwordSupport === 'REQUIRED')
		{
			this.password = value === undefined
				? ''
				: value

			setInLocalStorage(StorageVars.PlacePassword, value);
		}
		else
		{
			setInLocalStorage(StorageVars.PlacePassword);
		}

		this.setPasswordHasError(false);
	}

	setPasswordHasError(hasError: boolean): void
	{
		this.passwordHasError = hasError;
	}

	setCustomerCard(customerCard?: CustomerCard): void
	{
		if (this.place.customerCardSupport === 'DISALLOWED')
		{
			this.customerCard = undefined;

			setInLocalStorage(StorageVars.CustomerCard);
		}
		else
		{
			this.customerCard = customerCard;

			setInLocalStorage(StorageVars.CustomerCard, JSON.stringify(customerCard));
		}
	}

	setCustomerCardHasError(hasError: boolean): void
	{
		this.customerCardHasError = hasError;
	}

	setHasInteractedWithPaymentMethods(hasForcedFocus: boolean): void
	{
		this.hasInteractedWithPaymentMethods = hasForcedFocus;
	}

	addUserAcknowledgement(key: string, remember: boolean): void
	{
		if (!this.inMemoryUserAcknowledgements.includes(key))
			this.inMemoryUserAcknowledgements.push(key);

		if (remember)
		{
			const storageIdx = this.persistedUserAcknowledgements.get().indexOf(key);

			if (storageIdx === -1)
			{
				this.persistedUserAcknowledgements
					.set(this.persistedUserAcknowledgements.get().concat(key))
					.then(this.persistedUserAcknowledgements.syncWithStorage);
			}
		}
	}

	removeUserAcknowledgement(key: string, remember: boolean): void
	{
		const memoryIdx = this.inMemoryUserAcknowledgements.findIndex(peerKey => peerKey === key);

		if (memoryIdx !== -1)
			this.inMemoryUserAcknowledgements.splice(memoryIdx, 1);

		if (remember)
		{
			const storageIdx = this.persistedUserAcknowledgements.get().indexOf(key);

			if (storageIdx !== -1)
				this.persistedUserAcknowledgements
					.set(this.persistedUserAcknowledgements.get().splice(storageIdx, 1))
					.then(this.persistedUserAcknowledgements.syncWithStorage);
		}
	}

	public validateFormAndSendUserBackIfNecessary(): boolean
	{
		this.setShowValidation(true);

		if (this.shoppingCartStore.validation.length > 0)
		{
			this.setShoppingCartFocusHasFocus(true);
			return false;
		}

		if (this.hasDeliveryForm && this.clientNameStore.validation.length > 0)
		{
			this.setClientNameHasFocus(true);
			return false;
		}

		if (this.couponFormStore && this.couponFormStore.validation.length > 0)
		{
			this.setCouponHasFocus(true);
			return false;
		}

		for (let form of ['scheduler'] as OrderBuilderForm[])
		{
			let validationErrors = this.validationErrors.get(form);
			if (validationErrors !== undefined && validationErrors.length > 0)
			{
				this.setFocusedForm(form);
				return false;
			}
		}

		if (this.orderCommentStore !== undefined && this.orderCommentStore.validationErrors.length > 0)
		{
			this.setCommentFormHasFocus(true);
			return false;
		}

		if (this.needsPayment && this.paymentMethodHasError)
		{
			this.setPaymentMethodHasFocus(true);

			return false;
		}

		if (this.passwordHasError)
		{
			this.setPasswordFormHasFocus(true);

			return false;
		}

		if (this.customerCardHasError)
			return false;

		if (this.isAgeVerificationNotPassed)
			return false;

		if (!this.hasLegalConsent)
			return false;

		if (!this.userHasAcknowledgedRequiredAnnouncements)
			return false;

		if (this.currentOrderService.requireOrderTrackerConsent && !(this.currentOrderService.hasExplicitConsentForOrderTracker))
			return false;

		if (this.paymentSessionHasError)
			return false;

		return true;
	}

	attemptPlaceOrder(
		paymentMethod?: PaymentMethodDescriptor,
		paymentIssuer?: PaymentIssuer,
		adyenCheckoutData?: AdyenCheckoutData
	): Promise<Order>
	{
		const validated = this.validateFormAndSendUserBackIfNecessary();

		if (validated)
		{
			const effectivePaymentMethod = this.needsPayment
				? paymentMethod
				: undefined;

			return this
				.placeOrder(effectivePaymentMethod, paymentIssuer, adyenCheckoutData);
		}
		else
		{
			return Promise.reject(new Error("Could not validate order"));
		}
	}

	setShowValidation(showValidation: boolean)
	{
		this.showValidation = showValidation;

		if (this.hasDeliveryForm)
		{
			this.clientNameStore.setShowValidation(showValidation);
		}

		if (this.couponFormStore)
		{
			this.couponFormStore.setShowValidation(showValidation);
		}

		this.shoppingCartStore.setShowValidation(showValidation);
	}

	async placeOrder(
		paymentMethod: PaymentMethodDescriptor | undefined,
		paymentIssuer?: PaymentIssuer,
		adyenCheckoutData?: AdyenCheckoutData,
	): Promise<Order>
	{
		return placeProductOrder(
			{
				payment_issuer_id: paymentMethod?.isIssuerRequired
					? paymentIssuer?.id
					: undefined,
				cart_id: this.currentOrderService.ownCartId,
				client_name: this.currentOrderService.orderClientName,
				coupon_codes: this.currentOrderService.orderCouponCodes,
				como_asset_keys: this.currentOrderService.orderComoAssetKeys,
				new_payment_methods: true,
				os: getBackendOSValue(this.client),
				scheduled_for: this.currentOrderService.orderScheduledFor,
				client_phone_number: this.currentOrderService.orderClientPhoneNumber,
				client_email: this.currentOrderService.orderClientEmail,
				longitude: this.locationService.longitude,
				latitude: this.locationService.latitude,
				externalShopperNotificationId: this.currentOrderService.externalShopperNotificationId,
				externalShopperCardId: this.currentOrderService.externalShopperCardId,
			},
			{
				paymentMethodId: paymentMethod !== undefined
					? paymentMethod.id
					: TRADITIONAL_PAYMENT_METHOD_ID,
				comment: this.currentOrderService.orderComment,
				tip: this.currentOrderService.orderTip,
				lines: JSON.parse(this.orderLinesDescriptor),
				uuid: this.currentOrderService.orderUuid,
				destination_type: this.currentOrderService.effectiveDestinationType,
				address: this.currentOrderService.orderDestinationAddress,
				embeddableHtmlConsentObtained: this.currentOrderService.hasExplicitConsentForOrderTracker,
				placeId: this.place.id,
				password: this.password,
				customerCardId: this.customerCard?.id,
				ageVerificationChallengeId: this.currentAgeVerificationService.challenge?.id,
				adyenCheckoutPaymentInformation: isPaymentData(adyenCheckoutData)
					? adyenCheckoutData?.paymentMethod
					: undefined,
			},
		)
			.catch(error =>
			{
				if (error.name === 'UnauthorizedHttpRequestException')
					this.setPasswordHasError(true);

				// Check order that has been built so far
				this.currentOrderService.checkOrder();

				throw error;
			});
	}

	doAfterOrderPlacement(order: Order): void
	{
		this.setShowValidation(false);
		this.currentAgeVerificationService.setChallenge(undefined);
		this.currentOrderService.setOwnCartId(order.newCartId);
		this.orderService.reloadOrder(order.id);
	}

	async doAfterOrderComplete(): Promise<void>
	{
		try
		{
			await this.whenBlockingPaymentIsDone();
			await this.closeOrderBuilder();

			if (this.hasDeliveryForm)
				this.clientNameStore.afterOrder();

			this.couponFormStore?.afterOrder();

			// Wait a bit, as the navigator can act unpredictably on some mobile devices
			await new Promise(resolve => setTimeout(resolve, 500));

			await this.businessStore.resetNavigationAndOpenActiveOrders();
		}
		catch (error)
		{
			this.setShowValidation(true);
			alert('an error occured: ' + error.message);
		}
		finally
		{
			const cart = await fetch('/client/business/cart', undefined, CartProfile);

			this.currentOrderService.processNewCartFromServer(cart, cart.dateLastChanged);
		}
	}

	async closeOrderBuilder(): Promise<void>
	{
		this.inMemoryUserAcknowledgements.clear();

		const currentScreenInstance = this.bridge.navigator.currentScreenInstance;

		if (currentScreenInstance?.screen.id === Screens.OrderBuilder)
			await this.bridge.navigator.popScreen();

		this.setFocusedForm(null);

		await this.currentOrderService.cleanAfterOrder();
	}

	setShoppingCartFocusHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('shopping-cart'))
			this.setFocusedForm('shopping-cart');
		else if (!hasFocus && this.focusedForms.has('shopping-cart'))
			this.setFocusedForm(null);
	}

	setPaymentMethodHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('payment-method'))
			this.setFocusedForm('payment-method');
		else if (!hasFocus && this.focusedForms.has('payment-method'))
			this.setFocusedForm('shopping-cart');
	}

	setClientNameHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('client-name'))
			this.setFocusedForm('client-name');
		else if (!hasFocus && this.focusedForms.has('client-name'))
			this.setFocusedForm('shopping-cart');
	}

	setCouponHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('coupon'))
			this.setFocusedForm('coupon');
		else if (!hasFocus && this.focusedForms.has('coupon'))
			this.setFocusedForm('shopping-cart');
	}

	setSchedulerHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('scheduler'))
			this.setFocusedForm('scheduler');
		else if (!hasFocus && this.focusedForms.has('scheduler'))
			this.setFocusedForm('shopping-cart');
	}

	setCommentFormHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('comment'))
			this.setFocusedForm('comment');
		else if (!hasFocus && this.focusedForms.has('comment'))
			this.setFocusedForm('shopping-cart');
	}

	setPasswordFormHasFocus(hasFocus: boolean): void
	{
		if (hasFocus && !this.focusedForms.has('password'))
			this.setFocusedForm('password');
		else if (!hasFocus && this.focusedForms.has('password'))
			this.setFocusedForm('shopping-cart');
	}

	setFocusedForm(focusedForm: OrderBuilderForm | null)
	{
		Array.from(this.focusedForms)
			.filter(oldValue =>
			{
				// filter out forced focus items
				switch (oldValue)
				{
					case 'client-name':
						return !this.clientNameHasForcedFocus;
					case 'coupon':
						return !this.couponHasForcedFocus;
					case 'payment-method':
						return !this.paymentMethodHasForcedFocus;
					case 'scheduler':
						return !this.schedulerHasForcedFocus;
					default:
						return true;
				}
			})
			.forEach(oldValue =>
			{
				const newValue = focusedForm;

				if (oldValue === 'client-name' && newValue !== 'client-name')
				{
					if (!this.hasDeliveryForm)
					{
						throw new IllegalStateException();
					}
					this.clientNameStore.blur();
				}

				if (oldValue === 'coupon' && newValue !== 'coupon')
				{
					if (this.couponFormStore === null)
					{
						throw new IllegalStateException();
					}
					this.couponFormStore.blur();
				}

				this.focusedForms.delete(oldValue);
			});

		const newValue = focusedForm;
		if (newValue === 'client-name')
		{
			if (!this.hasDeliveryForm)
			{
				throw new IllegalStateException();
			}
			setTimeout(
				() =>
				{
					if (!this.hasDeliveryForm)
					{
						throw new IllegalStateException();
					}
					this.clientNameStore.focus();
				},
				100,
			);
		}

		if (newValue === 'coupon')
		{
			if (this.couponFormStore === null)
			{
				throw new IllegalStateException();
			}
			setTimeout(
				() => this.couponFormStore!.focus(),
				100,
			);
		}

		if (newValue !== null)
			this.focusedForms.add(newValue);
	}

	setHasLegalConsent(hasLegalConsent: boolean): void
	{
		this.currentOrderService.setHasLegalConsent(hasLegalConsent);
	}

	// ------------------------ Public logic ------------------------

	public getValidationErrors(form: OrderBuilderForm): IObservableArray<string>
	{
		if (!this.validationErrors.has(form))
		{
			this.validationErrors.set(form, observable.array<string>());
		}
		return this.validationErrors.get(form)!;
	}

	// ----------------------- Private logic ------------------------

	private whenBlockingPaymentIsDone(): Promise<void>
	{
		if (this.inTerminalPayment)
		{
			return this.orderService
				.when(
					this.orderService.mostRecentOrder,
					order => {
						const paymentState = order.paymentState;
						return paymentState === 'paid' || paymentState === 'failed';
					},
				);
		}
		else
		{
			return Promise.resolve();
		}
	}
}
