import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Heart } from 'mdi-material-ui';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { MenuStore } from '../MenuStore';

const useStyles = makeStyles<Theme>(() => ({
	Root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}));

interface MenuTopBarAppendixComoRewardsProps
{
	store: MenuStore;
}

export const MenuTopBarAppendixComoRewards = ({store}: MenuTopBarAppendixComoRewardsProps) =>
{
	const classes = useStyles();
	const screenWidth = useScreenWidth();
	const isSmallScreen = useMemo(() => screenWidth === 'xs', [screenWidth]);

	const onOpenPointShop = useCallback(
		() => store.businessStore.openComoRewardsPage(),
		[store],
	)

	return <Box
		className={classes.Root}
	>
		<IconButton
			color="inherit"
			size={isSmallScreen ? 'small' : 'medium'}
			onClick={onOpenPointShop}
		>
			<Heart />
		</IconButton>
	</Box>;
};
