import { Collapse, Fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { StorageVars } from '../../../../Constants/StorageConstants';
import { UIColors } from '../../../../Constants/UIColors';
import { convertFromISO8601Duration } from '../../../../Util/Duration/convertFromISO8601Duration';
import { useStorage } from '../../../Root/StorageContextProvider';
import { startPushNotifications } from '../../../ServiceWorker/startPushNotifications';
import { BillManager } from './BillManager/BillManager';
import { OrderHandlerNotificationProvider, useOrderHandlerNotify, useOrderHandlerNotifyOfOrder } from './context/notification/OrderHandlerNotificationProvider';
import { OrderHandlerOrderProvider, useOrderedOrders } from './context/order/order-handler-order-context';
import { OrderContainer } from './order-container/OrderContainer';
import { OrderHandlerToolbar } from './OrderHandlerToolbar/OrderHandlerToolbar';

const OrderHandlerToolbarHeight = 48;

const useStyles = makeStyles(() => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	root: {
		backgroundColor: UIColors.black,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	toolbarContainer: {
		boxShadow: '0px -1px 5px grey',
		zIndex: 0,
	},
}));

export const ManagerOrderHandler: FC =
	() => {
		return <OrderHandlerNotificationProvider>
			<OrderHandlerOrderProvider>
				<Inner />
			</OrderHandlerOrderProvider>
		</OrderHandlerNotificationProvider>;
	};

export const Inner: FC =
	() =>
	{
		const classes = useStyles();

		const [numberOfBills, setNumberOfBills] = useState<number>();

		useRegularDeviceNotificationOfCurrentOrderedOrders();

		const showBillManager = useShowBillManagerState();

		const {isBillManagerOpen, toggleBillManager} = useBillManagerOpenOrCloseState(numberOfBills);

		return <Fade
			in
		>
			<>
				<div className={classes.root}>
					<OrderContainer />
				</div>
				{
					showBillManager &&
					<Collapse
						classes={{
							root: classes.toolbarContainer,
						}}
						collapsedSize={`${OrderHandlerToolbarHeight}px`}
						in={isBillManagerOpen}
					>
						<OrderHandlerToolbar
							isBillManagerOpen={isBillManagerOpen}
							numberOfOpenBills={numberOfBills}
							onOpenBillManager={toggleBillManager}
						/>
						<BillManager
							onNumberOfBillsUpdate={setNumberOfBills}
							style={{
								maxHeight: 250,
							}}
						/>
					</Collapse>
				}
			</>
		</Fade>;
	};

function useRegularDeviceNotificationOfCurrentOrderedOrders()
{
	const storage = useStorage(true);
	const [notificationRecurrenceInterval, setNotificationRecurrenceInterval] = useState<number | undefined>(undefined);
	const notifyOpenOrders = useNotificationOfCurrentOrderedOrdersCallback();

	useEffect(
		() =>
		{
			let intervalHandler;

			if (notificationRecurrenceInterval !== undefined)
			{
				intervalHandler = setInterval(notifyOpenOrders, notificationRecurrenceInterval);
			}

			return () =>
				intervalHandler && clearInterval(intervalHandler);
		},
		[notificationRecurrenceInterval, notifyOpenOrders],
	);

	useEffect(
		() =>
		{
			startPushNotifications()
				.then(
					granted =>
					{
						granted && storage.get(StorageVars.ManagerNotificationRecurrence)
							.then(
								value =>
								{
									if (value)
									{
										const interval = convertFromISO8601Duration(value);

										setNotificationRecurrenceInterval(
											Math.max(10000, 1000 * (interval.hours * 3600 + interval.minutes * 60 + interval.seconds)));
									}
								});
					});
		},
		[storage]
	);
}

function useNotificationOfCurrentOrderedOrdersCallback()
{
	const translate = useTranslate();
	const orderedOrders = useOrderedOrders();
	const notify = useOrderHandlerNotify();
	const onNewOrder = useOrderHandlerNotifyOfOrder();
	return useCallback(
		() =>
		{
			if (orderedOrders)
			{
				if (orderedOrders.length === 1)
				{
					onNewOrder(orderedOrders[0]);
				}
				else if (orderedOrders.length > 1)
				{
					notify(translate('Manager-Notification-New-Orders-Waiting', orderedOrders.length.toString()));
				}
			}
		},
		[notify, onNewOrder, orderedOrders, translate],
	);
}

function useBillManagerOpenOrCloseState(numberOfBills: number | undefined)
{
	const [isBillManagerOpen, setIsBillManagerOpen] = useState(false);
	const toggleBillManager = useCallback(
		() =>
		{
			if (numberOfBills !== undefined && numberOfBills > 0)
			{
				setIsBillManagerOpen(!isBillManagerOpen);
			}
			else
			{
				setIsBillManagerOpen(false);
			}
		},
		[isBillManagerOpen, numberOfBills],
	);
	return {isBillManagerOpen, toggleBillManager};
}

function useShowBillManagerState()
{
	const storage = useStorage(true);
	const [showBillManager, setShowBillManager] = useState<boolean>();
	useEffect(
		() =>
		{
			storage.get(StorageVars.ManagerShowOpenBills)
				.then(value =>
				{
					if (value)
					{
						setShowBillManager(value === 'true');
					}
					else
					{
						storage.set(StorageVars.ManagerShowOpenBills, 'true').then();
						setShowBillManager(true);
					}
				});
		},
		[storage],
	);
	return showBillManager;
}
