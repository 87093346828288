import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Order } from '../../Api/Order/Order';
import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { Linking } from '../../Bridge/Linking/Linking';
import { fetchAny } from '../../Util/Api';
import { getLastClientOrders } from './Api/Client/getClientOrders';
import { OrderStateListener } from './OrderStateListener';

export class OrderService
{
	/*---------------------------------------------------------------*
	 *                         Dependencies                          *
	 *---------------------------------------------------------------*/

	readonly linking: Linking;

	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	/**
	 * All {@link OrderDescriptor}s placed by {@link Client}
	 */
	observableOrders = observable.array<OrderDescriptor>();

	/**
	 * All {@link OrderDescriptor}s placed by {@link Client}
	 */
	ordersById = observable.map<number, OrderDescriptor>();

	/**
	 * A callback that gets executed for every {@link OrderDescriptor} update, taking the old version of the {@link OrderDescriptor}
	 * (from before the update) and the new version of the {@link OrderDescriptor} (from after the update)
	 */
	onUpdate: (newVersionOfOrder: OrderDescriptor, oldVersionOfOrder?: OrderDescriptor) => void;

	listeners = observable.array<OrderStateListener>();

	didInitialize: boolean = false;

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor(linking: Linking)
	{
		makeObservable(
			this,
			{
				observableOrders: observable,
				ordersById: observable,
				onUpdate: observable,
				listeners: observable,
				didInitialize: observable,
				orders: computed,
				ordersCreatedToday: computed,
				mostRecentOrder: computed,
				addOrUpdateOrders: action.bound,
				addOrUpdateOrder: action.bound,
				doOnUpdate: action.bound,
				tellListeners: action.bound,
				removeOrder: action.bound,
				when: action.bound,
				setOnUpdate: action.bound,
				startOrderPayment: action.bound,
				setDidInitialize: action.bound,
			},
		);

		this.linking = linking;
	}

	/*---------------------------------------------------------------*
	 *                        Initialization                         *
	 *---------------------------------------------------------------*/

	async initialize(): Promise<void>
	{
		if (!this.didInitialize)
		{
			const orders = await getLastClientOrders();

			this.addOrUpdateOrders(orders);

			this.setDidInitialize(true);
		}
	}

	/*---------------------------------------------------------------*
	 *                           Computed                            *
	 *---------------------------------------------------------------*/

	get ordersCreatedToday(): OrderDescriptor[]
	{
		const now = new Date();
		now.setHours(0, 0, 0, 0);

		return this.observableOrders.filter(
			order =>
			{
				const dateCreated = order.dateScheduled ?? order.dateOrdered;

				if (dateCreated === undefined)
				{
					return true;
				}
				else
				{
					const date = new Date(dateCreated);
					date.setHours(0, 0, 0, 0);

					// call setHours to take the time out of the comparison
					return date.getTime() === now.getTime();
				}
			},
		);
	}

	get orders(): OrderDescriptor[]
	{
		return toJS(this.observableOrders);
	}

	get mostRecentOrder(): OrderDescriptor | undefined
	{
		if (this.observableOrders.length > 0)
			return toJS(this.observableOrders[0]);
		else
			return undefined;
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	setDidInitialize(didInitialize: boolean): void
	{
		this.didInitialize = didInitialize;
	}

	setOnUpdate(onUpdate: (order: OrderDescriptor, oldOrder?: OrderDescriptor) => void): void
	{
		this.onUpdate = onUpdate;
	}

	addOrUpdateOrders(orders: OrderDescriptor[]): void
	{
		this.observableOrders.clear();
		this.ordersById.clear();

		orders
			.slice()
			.reverse()
			.forEach(
				order =>
					this.addOrUpdateOrder(order),
			);
	}

	/**
	 * Any new version of an {@link OrderDescriptor} object can be added to {@link OrderService} with this method. This method is idempotent:
	 * adding an equivalent {@link OrderDescriptor} object more than once will not lead to a different state than to add it only once, also
	 * meaning it will not cause {@link OrderService} to emit duplicate events.
	 */
	addOrUpdateOrder(order: OrderDescriptor): void
	{
		const oldOrder = this.ordersById.get(order.id);

		/*
		 * Update this.orders
		 */
		if (oldOrder)
		{
			const orderIdx = this.getOrderIndex(order, this.observableOrders);

			if (orderIdx >= 0)
				this.observableOrders.splice(orderIdx, 1, order);
		}
		else
		{
			this.observableOrders.unshift(order);
		}

		/*
		 * Update this.ordersById
		 */
		this.ordersById.set(order.id, order);

		/*
		 * Call callbacks
		 */
		this.doOnUpdate(order, oldOrder);
	}

	doOnUpdate(newVersionOfOrder: OrderDescriptor, oldVersionOfOrder?: OrderDescriptor)
	{
		this.tellListeners(newVersionOfOrder);

		this.onUpdate?.(newVersionOfOrder, oldVersionOfOrder);
	}

	tellListeners(newVersionOfOrder: OrderDescriptor)
	{
		const listenersResolved: OrderStateListener[] = [];

		this.listeners.filter(
			listener =>
			{
				if (listener.orderId === undefined || listener.orderId === newVersionOfOrder.id)
				{
					if (listener.predicate(newVersionOfOrder))
					{
						return true;
					}
				}
				return false;
			})
			.forEach(listener =>
			{
				listener.resolve();
				listenersResolved.push(listener);
			});

		listenersResolved.forEach(
			listener =>
				this.listeners.remove(listener),
		);
	}

	removeOrder(order: OrderDescriptor): void
	{
		const orderIdx = this.getOrderIndex(order, this.observableOrders);

		if (orderIdx >= 0)
		{
			this.observableOrders.splice(orderIdx, 1);
		}
	}

	when(
		order?: OrderDescriptor,
		predicate?: (order: OrderDescriptor) => boolean): Promise<void>
	{
		let promise: Promise<void> = new Promise((resolve, reject) =>
		{
			this.listeners.push(
				new OrderStateListener(
					order && order.id,
					predicate,
					resolve,
					reject,
				));
		});
		this.observableOrders
			.filter(o => order === undefined || order.id === o.id)
			.forEach(this.tellListeners);
		return promise;
	}

	startOrderPayment(order: Order)
	{
		if (order.paymentUrl)
		{
			return this.linking.canOpenUrl(order.paymentUrl)
				.then(canOpen =>
				{
					if (canOpen)
					{
						return this.linking.openUrl(order.paymentUrl);
					}
					else
					{
						return Promise.resolve();
					}
				});
		}

		return Promise.resolve();
	}

	async reloadOrder(orderId: number, order?: OrderDescriptor): Promise<void>
	{
		if (order === undefined)
		{
			this.addOrUpdateOrder(
				await fetchAny<OrderDescriptor>(
					`/client/business/order`,
					{
						orderId,
					},
				),
			);
		}
		else
		{
			this.addOrUpdateOrder(order);
		}
	}

	protected getOrderIndex(order: OrderDescriptor, orderArray: OrderDescriptor[]): number
	{
		return orderArray.findIndex(
			peerOrder =>
				peerOrder.id === order.id,
		);
	}
}
