import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { PageDialog } from '../../UI/PageDialog';
import { PlaceCodeScanner } from '../../UI/PlaceCodeScanner/PlaceCodeScanner';
import { BusinessStore } from '../Business/BusinessStore';
import { ScannerStore } from './ScannerStore';

const notificationAutoHideDurationInMs = 1000;

const useStyles = makeStyles(() => ({
    closeIconStreamActive: {
        color: 'white',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        margin: '0px !important',
        padding: '0px !important',
    },
    dialogContent: {
        display: 'flex',
        padding: '0px !important',
    },
    paper: {
        alignSelf: 'stretch',
    },
    placeCodeScanner: {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
    },
    placeCodeScannerRoot: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
}));

interface ScannerProps
{
    store: ScannerStore<BusinessStore>
}

const Scanner: FC<ScannerProps> =
    (
        {
            store,
        }
    ) =>
    {
        useEffect(
            () => {
                store.setQrCodeValidity('none');
                store.setPlaceCodeScannerLoaded(false);
                store.setStartTimestamp();
                store.setLastReportedCode('');

                window.addEventListener('beforeunload', () => {
                    try
                    {
                        store.reportScan(false, undefined, undefined, 'Webapp refreshed or closed');
                    }
                    catch (ignored)
                    {
                        console.warn(`onbeforeunload event went wrong:\n${ignored.stack}`)
                    }
                });

                return () => {
                    if (store.qrCodeValidity !== 'valid')
                    {
                        onBack(store);
                    }
                };
            },
            [store]
        )
        const classes = useStyles();
        return <>
            <PageDialog
                classes={{
                    closeIcon: store.placeCodeScannerLoaded ? classes.closeIconStreamActive : undefined,
                    content: classes.content,
                    DialogContent: classes.dialogContent,
                    Paper: classes.paper,
                }}
                fullWidth
                onClose={store.close}
            >
                <PlaceCodeScannerSection store={store}/>
            </PageDialog>
            {store.placeCodeScannerStore.type === 'stream' && <ErrorSnackbar store={store}/>}
            {store.placeCodeScannerStore.type === 'stream' && <InvalidSnackbar store={store}/>}
            {store.placeCodeScannerStore.type === 'stream' && <UnknownSnackbar store={store}/>}
        </>;
    };

function onBack(store: ScannerStore<BusinessStore>): void
{
    store.reportScan(false, undefined, undefined, 'Scanner closed');
}

const InvalidSnackbar: FC<ScannerProps> = ({store}) => {
    return <Snackbar
        open={store.qrCodeValidity === 'invalid'}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        autoHideDuration={notificationAutoHideDurationInMs}
        onClose={
            () =>
                store.setQrCodeValidity('none')
        }
    >
        <SnackbarContent
            message={store.bridge.localizer.translate('Qr-Code-Invalid')}
        />
    </Snackbar>;
}

const ErrorSnackbar: FC<ScannerProps> = ({store}) => {
    return <Snackbar
        open={store.qrCodeValidity === 'error'}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        autoHideDuration={notificationAutoHideDurationInMs}
        onClose={
            () =>
                store.setQrCodeValidity('none')
        }
    >
        <SnackbarContent
            message={store.bridge.localizer.translate('Qr-Code-Error')}
        />
    </Snackbar>;
}

const UnknownSnackbar: FC<ScannerProps> = ({store}) => {
    return <Snackbar
        open={store.qrCodeValidity === 'unknown'}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        autoHideDuration={notificationAutoHideDurationInMs}
        onClose={
            () =>
                store.setQrCodeValidity('none')
        }
    >
        <SnackbarContent
            message={store.bridge.localizer.translate('Qr-Code-Unknown')}
        />
    </Snackbar>;
}

const PlaceCodeScannerSection: FC<ScannerProps> = ({store}) => {
    const classes = useStyles();
    return <PlaceCodeScanner
        classes={{
            root: classes.placeCodeScannerRoot,
            scanner: classes.placeCodeScanner,
        }}
        store={store.placeCodeScannerStore}
    />;
}

export default Scanner;