import { Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { default as React, FC, useMemo } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { ComoRewardStore } from './ComoRewardStore';

interface ComoRewardDialogActionsProps
{
	store: ComoRewardStore;
}

export const ComoRewardDialogActions: FC<ComoRewardDialogActionsProps> =
	({
		store,
	}) =>
	{
		const translate = useTranslate();

		const label = useMemo(() =>
		{
			if (store.reward.points === undefined)
				return undefined;
			else
			{
				const points = new Decimal(store.reward.points)
					.mul(
						new Decimal(10)
							.pow(-store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces),
					)
					.toString();

				return `${points} ${translate('Client-Loyalty-Como-Points')}`;
			}
		}, [store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces, store.reward.points, translate]);

		const isRedeemed = useObserver(() => store.isRedeemed);
		const pointBalance = useObserver(() => store.comoRewardsStore.membership.points);

		return <>
			{
				store.reward.points !== undefined &&
				<Typography
					variant="subtitle1"
					color="textPrimary"
					style={{
						textAlign: 'left',
						marginRight: 'auto',
					}}
				>
					{
						label
					}
				</Typography>
			}
			<ActionButton
				id="como-reward-dialog-add-button"
				text={
					store.reward.points === undefined
						? translate('Client-Order-Add[ItemToOrder]')
						: translate('Client-ShoppingCart-Order')
				}
				disabled={isRedeemed || store.reward.points > pointBalance}
				onClick={() => store.redeem()}
				size="medium"
				style={{
					alignSelf: 'stretch',
					margin: 0,
				}}
				icon={ShoppingCartIcon}
			/>
		</>;
	};